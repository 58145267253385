@import "../functions/_@mixin-module.scss";

:root {
  --get-nav-height: 110px;
  --get-nav-width: 110px;
}

// Theme button's style
%theme_btn_style {
  font-size: font(s16);
  width: max-content;
  min-width: 100px;
  padding: 6.4px 28px;
  display: inline-block;

  @media (max-width: 767.98px) {
    font-size: font(s13);
    display: initial;
    padding: 6.4px 22px;
  }

  @media (max-width: 767.98px) {
    padding: 6px 15px;
    min-width: 83px;
  }

  @media (max-width: 450px) {
    padding: 5px 17px;
  }

  @media (max-width: 370px) {
    min-width: 74px;
  }
}

%btn-small {
  @include font_sty(font(s26), $fs_sty: uppercase);
  min-width: 211px;
  padding: 8px 22px;
  @media (max-width:767.98px){
    min-width: auto !important;
  }
}

%btn-large {
  @include font_sty(font(s16), $fs_sty: uppercase);
  min-width: 325px;
  padding: 10px 22px;

  @media (max-width: 1600px) {
    min-width: 284px;
    padding: 9px 30px;
  }

  @media (max-width: 767.98px) {
    padding: 9px 22px;
    width: 284px;
    min-width: 284px;
  }

  @extend %Removed_extra_sty;
}

%btn-x-large {
  @include font_sty(font(s24), $fs_sty: uppercase, $xxl_fs: font(s35));
  min-width: 200px;
  padding: 10px 22px;
  width: 100%;
  max-width: 100%;
}

%Removed_extra_sty {
  outline: none !important;
  box-shadow: none !important;

  &:hover,
  &:focus,
  &:focus-visible,
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
}

.privatePolicyPunch {
  text-decoration: underline;
  color: black;

  &:hover,
  &:focus,
  &:focus-visible,
  &:active,
  &:visited {
    text-decoration: underline;
    color: black;
  }
}

.privatePolicyText {
  font-size: font(s15) !important;
}

.pt-156 {
  padding-top: 156px !important;
  @media (min-width: 768px) and (max-width: 1180px) {
    padding-top: 150px !important;
  }
  @media (max-width:787.99px){
    padding-top: 108px !important;
  }
}

.pt-106{
  padding-top: 106px !important;
  @media (max-width:787.99px){
    padding-top: 75px !important;
  }
}