@import "../../styles/base/_baseModule";

.menu_tabs {
  place-content: start;
  border-bottom: 0;
  margin-bottom: 0;
  .nav_item {
    @media (max-width: 767.98px) {
      position: relative;
    }
    &:first-child {
      @media (max-width: 767.98px) {
        margin-left: 0px;
      }
    }
    &:last-child {
      margin-right: 0;
    }
    a {
      @include font-sty(
        font(s18),
        Pri_clr(lt-black),
        font_fam(rale),
        $sm_fs: font(s14),
        $fs_weight: 500,
        $fs_lh: 1.1,
        $fs_space: 0.5px
      );
      padding: 9px 15px;
      min-width: auto;
      white-space: nowrap;
      @media (max-width: 767.98px) {
        padding: 7px 15px;
        @include font-sty(font(s14) !important, $fs_weight: 400);
      }
    }
  }
}
.menu_start_order_btn {
  position: fixed;
  right: 48px;
  top: calc(var(--get-nav-height) + 120px);
  z-index: 99;
  transition: all 0.3s ease-in-out;
  @media (max-width: 1250px) {
    top: calc(var(--get-nav-height) + 60px);
    right: 30px;
    .item_order_btn_new {
      min-width: 200px !important;
      width: 200px !important;
      font-size: font(s14) !important;
      padding: 7px 22px !important;
    }
  }
}
.item_icon_wrap {
  margin-left: 10px;
  .legent_item {
    .tooltip_text {
      position: relative;
      background-color: #98be34;
      color: #fff !important;
      border: none !important;
      border-radius: 0 !important;
      font-family: "proxima_novaregular";
      font-size: font(s13);
      padding: 2px 10px;
      max-width: 150px;
      width: 100px;
      z-index: 99;
      font-weight: normal;
      margin-left: 15px;

      &:before {
        content: "";
        content: "";
        position: absolute;
        border-width: 6.5px;
        border-style: solid;
        border-color: transparent;
        border-right-color: #98be34;
        top: 50%;
        left: -6.5px;
        border-left-width: 0;
        transform: translateY(-50%);
      }
      &.keto_tooltip_text {
        background-color: #f3a153;
        &:before {
          border-right-color: #f3a153;
        }
      }
    }
    img {
      width: auto;
      height: 25px;
    }
  }
}
.description_container {
  background: $moongia_green;
  color: white;
  h3 {
    margin: 0;
    text-transform: uppercase;
  }
  p {
    color: white;
    margin: 0;
  }
  @media (max-width: 767.98px) {
    text-align: left;
  }
}
.title_wrapper {
  height: calc(100vh - 100px);
  overflow: auto;
  padding-bottom: 20px;
  .title_container_wrap {
    position: sticky;
    top: 0;
    z-index: 99;
  }
  @media (min-width: 1601px) {
    height: calc(100vh - 117px);
  }
  @media (max-width: 767.98px) {
    height: calc(100vh - 59px);
  }
}
.filters_btns {
  min-width: 80px;
  height: 40px;
  @media (max-width: 767.98px) {
    position: fixed;
    right: 24px;
    top: calc(var(--get-nav-height) + 30px);
    z-index: 1;
    height: auto;
    min-width: 62px;
  }
  .search_btn {
    background: url("../../images/Searchbar-icon.svg") no-repeat;
    background-size: contain;
    width: 23px;
    min-width: 23px;
    height: 23px;
    border: none !important;
    background-color: transparent !important;
    outline: none !important;
    box-shadow: none !important;
    font-size: font(s17);
    padding-bottom: 5px;
    margin-right: 8px;
    &:hover,
    &:focus,
    &:active,
    &.active {
      filter: invert(28%) sepia(6%) saturate(2886%) hue-rotate(154deg)
        brightness(88%) contrast(88%);
    }
  }
  .filter_btn {
    @extend .search_btn;
    background: url("../../images/SlidersHorizontal.svg") no-repeat;
    width: 30px;
    min-width: 30px;
    height: 28px;
    margin-right: 0;
  }
}
.filter_wrapper {
  top: calc(var(--get-nav-height) + 155px);
  transition: all 0.4s ease-in-out;
  width: 100%;
  opacity: 1;
  visibility: visible;
  height: 300px;
  &::after {
    content: "";
    position: absolute;
    width: calc(100% + 96px);
    left: -48px;
    height: calc(var(--get-nav-height) + 100vh);;
    background-color: #3d393533;
    top: calc(var(--get-nav-height) + 275px);
    z-index: 9;
  }
  &.hide {
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
  .filter_inner_wrap {
    padding-top: 20px;
    background-color: #fff;
    max-width: 100%;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background-color: #fff;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      right: -80%;
      @media (max-width: 767.98px) {
        right: -90%;
      }
    }
    .filter_list {
      padding-bottom: 9px;
      margin-bottom: 0;
      label {
        font-size: font(s18);
        .symbol_icon {
          min-width: 55px;
          margin-right: 10px;
          margin-left: 8px;
        }
      }
    }
    @media (max-width: 767.98px) {
      height: 100%;
    }
  }
  @media (max-width: 767.98px) {
    height: 322px;
    top: calc(var(--get-nav-height) + 8px);
    z-index: 99;
    &::after {
      top: 400px;
    }
    button {
      top: 0;
    }
  }
  .btn_close {
    @include media-breakpoint-down(md) {
      top: 6px;
    }
    z-index: 9;
  }
}
.hide_content {
  opacity: 0;
  visibility: hidden;
}
.search_bar_wrap {
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 99;
  top: 265px;
  background-color: #3d393533;
  .search_inn_content {
    background-color: #fff;
    padding: 20px 0;
    height: 51px;
    .search_bar_icon {
      img {
        width: 30px;
      }
    }
    .search_close_btn {
      width: 23px;
      height: 23px;
    }
    .form_control {
      border: none;
      box-shadow: none !important;
      outline: none !important;
      border-radius: 0;
      height: 25px;
      width: 100%;
    }
  }
  .Search_active {
    .menu_tabs {
      display: none;
    }
  }
  @media (max-width: 767.98px) {
    top: 225px !important;
    .form_control {
      width: calc(100% - 50px) !important;
    }
    .search_bar_icon {
      img {
        width: 21px !important;
      }
    }
  }
  &.track_here_search{
    @media (max-width: 767.98px) {
      top: 245px !important;
    }
  }
}


.search_result_btn {
  line-height: 1.2;
}
.menu_items_scrollbar {
  max-width: calc(100vw - 96px);
  width: calc(100vw - 96px) !important;
  height: 66px !important;
  background-color: white;
  @media (max-width: 767.98px) {
    height: 46px !important;
    max-width: calc(100vw - 40px);
    width: calc(100vw - 40px) !important;
    margin-left: 16px;
  }
  .Scrollbar_view {
    max-width: max-content;
    width: auto;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    overflow-y: hidden !important;
    padding-left: 7px;
    padding-right: 7px;
    gap: 14px;

    @media (max-width: 767.98px) {
      height: 55px !important;
      gap: 10px;
      &::-webkit-scrollbar {
        width: 0 !important;
        background: transparent !important;
        opacity: 0 !important;
        visibility: hidden !important;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: none !important;
        border-radius: 0 !important;
        opacity: 0 !important;
        visibility: hidden !important;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 0 !important;
        -webkit-box-shadow: none !important;
        background: transparent !important;
        opacity: 0 !important;
        visibility: hidden !important;
      }
    }
  }
  .Scrollbar_thumb {
    background-color: Sec_clr(lt-grey);
    border-radius: 20px;
  }
}

.no_menu_category_bar {
  padding-top: 0 !important;
}
@-moz-document url-prefix() {
  .Scrollbar_view {
    @media (max-width: 767.98px) {
      padding-bottom: 10px;
    }
  }
}

.kioskModalloginSuccessbtn{
  button{
      min-width: max-content;
  }
}