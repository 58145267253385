@import "../../styles/functions/_@mixin-module";

.footer_img_wrap {
  img {
    max-height: 200px;
    object-fit: cover;
    object-position: top;
  }
}

.footer_div {
  padding: 50px 0 20px;
  width: 100%;
  background-color: Pri-clr(dark-blue) !important;

  .footer_menu_links_wrap {
    .nav_menu_items_wrapper {
      @media (max-width: 991.98px) {
        border-bottom: 1px solid #fff;
      }

      nav {
        padding: 0 !important;
      }
    }
  }

  @media (max-width: 991.98px) {
    padding: 20px 0;
  }

  .FooterBottomLinks {
    margin-top: 30px;

    ul {
      display: flex;
      justify-content: center;

      @media (max-width: 991.98px) {
        flex-direction: column;
        justify-content: start;

        li {
          text-align: left;

          &::before {
            display: none;
          }
        }
      }

      li {
        list-style: none;
        position: relative;
        padding: 0 16.5px;
        letter-spacing: 1.5px;

        @media (max-width: 991.98px) {
          padding-left: 0;
          margin-bottom: 8px;
        }

        &:nth-child(2),
        &:nth-child(3) {
          &::before {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0px;
            display: inline-block;
            width: 5px;
            min-width: 5px;
            height: 5px;
            background: Sec_clr(sec-white);
            border-radius: 50%;

            @media (max-width: 991.98px) {
              display: none;
            }
          }
        }

        a {
          text-decoration: none;
          color: #ffffff;
        }
      }
    }
  }
}

.social_icon_container {
  padding: 0;
  border-left: 1px solid #fff;
  text-align: left;

  @media (max-width: 991.98px) {
    border-left: 0;
    border-bottom: 1px solid #fff;
    padding-bottom: 24px;
    margin-bottom: 30px;
  }

  h6 {
    text-transform: uppercase;

    @media (min-width: 991px) and (max-width: 1200px) {
      @include font_sty(
        font(s22) !important,
        $fs_lh: 117.2% !important,
        $fs_space: 0.5008px !important
      );
    }

    @media (max-width: 991.98px) {
      @include font_sty(
        font(s18) !important,
        $fs_lh: 21.6px !important,
        $fs_space: 1px !important
      );
    }
  }

  nav {
    flex-direction: row;
    justify-content: start;
    padding: 10px 0 !important;
    width: 100%;

    svg {
      width: 36px;
      height: 36px;

      @media (max-width: 991.98px) {
        width: 24px;
        height: 24px;
      }
    }

    a {
      padding: 3px 10px !important;

      @media (max-width: 400px) {
        padding: 3px 5px !important;
      }

      &:first-child {
        @media (min-width: 768px) {
          padding-left: 0 !important;
        }
      }

      &:nth-of-type(3) {
        border-bottom: none;
        padding-bottom: 0 !important;
        margin-bottom: 0;
        width: auto;
      }

      &:last-child {
        padding-right: 0 !important;
      }

      @media (min-width: 1200px) {
        img {
          height: 24px;
        }

        &:nth-child(2) {
          img {
            height: 27px;
          }
        }
      }

      &:hover,
      &:focus,
      &:focus-visible {
        animation: beatHeart 0.8s infinite;
        outline: unset;
      }
    }
  }

  @keyframes beatHeart {
    0% {
      transform: scale(1);
    }

    25% {
      transform: scale(1.1);
    }

    40% {
      transform: scale(1);
    }

    60% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }
}

.footer_links_wrap:hover svg path {
  fill: Pri-clr(dark-blue);
}

.modal_close_button {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: transparent;
  background: url("#{$REACT_APP_CLOUD_FRONT}/images/icons/x-icon.svg") no-repeat;
  background-size: contain;
  opacity: 1;
  outline: none !important;
  box-shadow: none !important;
  position: absolute;
  top: 27px;
  right: 22px;
  z-index: 999;
  font-size: 0;

  @media (min-width: 768px) {
    top: -12px;
    right: -29px;
  }
}

.bottom_text {
  @include font-sty(font(s13) !important, Pri_clr(lt-black));
  margin-top: 24px;

  a {
    color: Pri_clr(lt-black) !important;
    text-decoration: underline;
  }
}

.error {
  @include font_sty(font(s10), red);
  float: left;
}

.footer_newsletter {
  border-right: 1px solid Sec_clr(sec-white);

  h5 {
    @media (min-width: 991px) and (max-width: 1200px) {
      @include font_sty(
        font(s22) !important,
        $fs_lh: 117.2% !important,
        $fs_space: 0.5008px !important
      );
    }
  }

  button {
    @media (min-width: 768px) {
      width: 260px !important;
      min-width: 260px !important;
    }
  }
}

li {
  list-style: none;
}

.footerWrapper {
  border-radius: 15px;
  background: Pri_clr(dark-blue);
  padding: 100px 0px;
  margin: 0 45px 45px 45px;
  @media (max-width: 767.98px) {
    padding: 30px 0px;
    margin: 0 10px 20px 10px;
  }
  .footerTitle {
    font-family: "freightdispcmp-pro", serif;
    font-size: 95px;
    font-weight: 400;
    line-height: 82.65px;
    letter-spacing: -0.04em;
    text-align: center;
    color: #fff;
    @media (max-width: 767.98px) {
      font-size: 33px;
      border-bottom: 0.25px solid #f3f1e9;
    }
  }

  .socialIcons {
    display: flex;
    gap: 14px;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 50px;
    @media (max-width: 767.98px) {
      margin-bottom: 0px;
      gap: 10px;
    }
    li {
      a {
        svg {
          transition: transform 0.3s ease;
          @media (max-width: 767.98px) {
          height: 24px;
          width: 24px;
          }

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  .footerLinks {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    @media (max-width: 767.98px) {
      justify-content: space-between;
      border-top: 0.25px solid #f3f1e9;
    }
    li {
      position: relative;
      padding: 0 20px;
      @media (max-width: 767.98px) {
        padding: 0 12px;
      }
      &::after {
        content: "";
        height: 62%;
        width: 1px;
        background: #fff;
        position: absolute;
        left: 100%;
        top: 8px;
        @media (max-width:767.98px) {
          display: none;
        }
      }
      a {
        font-family: "trade-gothic-next", sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 31.68px;
        letter-spacing: -0.04em;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        text-decoration: none;
        @media (max-width: 767.98px) {
          font-family: "trade-gothic-next", sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 21.28px;
          letter-spacing: -0.04em;
          text-align: center;
        }
      }
      .lineShape {
        width: 1px;
        height: 100%;
        display: inline-block;
        background: #fff;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
}
