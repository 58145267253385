/* Skeleton.css */
.skeleton {
    background-color: #e0e0e0;
    border-radius: 4px;
    width: 100%; /* Adjust width based on your layout */
    height: 1.2em; /* Adjust height to match the h4 text */
    position: relative;
    overflow: hidden;
  }
  
  .skeleton::after {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent);
    animation: loading 1.5s infinite;
  }
  
  @keyframes loading {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(100%); }
  }
  
  /* Custom size for h4 */
  .h4_skeleton {
    height: 1.4em; /* Match this to your specific h4's font size */
  }
  