$sky_blue: #61dafb;
$yellowish: rgb(204, 204, 49);
$white: white;
$yellow: rgb(226, 192, 37);
$sky_blue_light: #0956A8;
$orange: rgb(244, 127, 77);
$moongia_green: rgb(134, 166, 121);
$orange_shade: rgb(244, 128, 78);
$dark_grey: #d1d1d1;
$text_color: #666766;
$medium_gray: #9d9c9a;
$cz_gray: #767676;
$cz_beige: #F3F1E9;
$cz_green: #198000;
$cz_yellow: #F7D96E;
$cz_sharp_yellow: #FFCE22;
$cz_red: #C53E00;
$cz_burnt_orange: #b53c04;

$colors: (
  "b-charcoal": #282c34,
  "s-blue": #61dafb,
  "yellowish-clr": rgb(204, 204, 49),
  "g-grey": rgb(134, 161, 121),
  "white-clr": #fff,
  "g-black": rgb(83, 87, 90),
  "b-grey": rgb(47, 47, 46),
  "yellow-clr": rgb(226, 192, 37),
  "org": rgb(244, 127, 77),
  "m-green": rgb(134, 166, 121),
  "org-shade": rgb(244, 128, 78),
  "l-grey": #ccc,
  "d-grey": #d1d1d1,
  "d-color": #646464e8,
  "input-color": #c9cacc,
  "sb-dark": #0956A8,
  "sb-light": #0956A8,
  "red-dark": #aa182c,
  "green-clr": #85a079,
  "dark-grey": #3d3935,
  "lite-grey": #f8f7f2,
);

$borderRadius: (
  "8": 8px,
  "24": 24px,
);

@each $radius, $val in $borderRadius {
  .border-radius-#{$radius} {
    border-radius: $val !important;
  }
}

@function font_clr($fs_color) {
  @return map-get($colors, $fs_color);
}

@each $color, $val in $colors {
  .clr-#{$color} {
    color: $val !important;
  }
  .bg-clr-#{$color} {
    background-color: $val !important;
  }
}

$secondary_clr: (
  "dark-red": #aa182c,
  "red-devil": #820012,
  "dark-orng": #df854f,
  "lt-orng": #e37736,
  "dark-green": #759169,
  "lt-grey": #9d9c9a,
  "lt-blue": #a6c2cf,
  "lt-green": #c2cebc,
  "murcury-clr": #e1e1e1,
  "sec-white": #fff,
  "text-grey": #767676,
  "medium-grey": #afafaf,
);

@function Sec_clr($Sec_clr) {
  @return map-get($secondary_clr, $Sec_clr);
}
@each $sec_clr, $val in $secondary_clr {
  .clr-#{$sec_clr} {
    color: $val !important;
  }
  .bg-clr-#{$sec_clr} {
    background-color: $val !important;
  }
}
$primary_clr: (
  "lt-black": #3d3935,
  "cod-grey": #191918,
  "dark-blue": #0956A8,
  "chathams-blue": #155a79,
  "green-grey": #85a079,
  "highland-green": #759069,
  "chalet-green": #4a653d,
  "black": #000000,
  "prussian-blue": #033e7b,
  "light-green": #198000,
);
@function Pri_clr($Pri_clr) {
  @return map-get($primary_clr, $Pri_clr);
}
@each $pri_clr, $val in $primary_clr {
  .clr-#{$pri_clr} {
    color: $val !important;
  }
  .bg-clr-#{$pri_clr} {
    background-color: $val !important;
  }
}

.bg-white {
  background-color: $white;
}
.fs30 {
  font-size: 1.875rem;
}
.color-sky-blue-light {
  color: $sky_blue_light !important;
}
.color-sky-blue {
  color: $sky_blue !important;
}
.bccolor-sky-blue-light,
.bccolor-sky-blue-light:hover {
  border-color: $sky_blue_light !important;
}
.color-yellow {
  color: $yellow !important;
}
.color-white {
  color: $white !important;
}
.color-medium-gray {
  color: $medium_gray !important;
}
.cz-clr-gray {
  color: $cz_gray !important;
}
.cz-clr-green{
  color: $cz_green !important;
}
.color-white{
  color: $white !important;
}