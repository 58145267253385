@import "../base/cloudfront_url_base.scss";
@import url("https://p.typekit.net/p.css?s=1&k=uah5nsc&ht=tk&f=39680.39685.39691.39695.44360.44362&a=246984558&app=typekit&e=css");

@font-face {
  font-family: "visby_cfextra_bold";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/visbycf-extrabold-webfont.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/visbycf-extrabold-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova Lt";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/ProximaNova-Semibold.eot");
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/ProximaNova-Semibold.eot?#iefix")
      format("embedded-opentype"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/ProximaNova-Semibold.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/ProximaNova-Semibold.woff")
      format("woff"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/ProximaNova-Semibold.ttf")
      format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

// Visby CF Extra
@font-face {
  font-family: "Visby CF Extra";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Visby-CF-Extra-Bold/#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-ExtraBold.eot");
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Visby-CF-Extra-Bold/#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Visby-CF-Extra-Bold/#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-ExtraBold.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Visby-CF-Extra-Bold/#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-ExtraBold.woff")
      format("woff"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Visby-CF-Extra-Bold/#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-ExtraBold.ttf")
      format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

$fonts_family: (
  "Vcf_bold": "visby_cfextra_bold",
  "pn_lt": "Proxima Nova Lt",
  "rale": "Raleway",
  "Vcf": "Visby CF Extra",
  "Cls": "Clarendon LT Std",
  "Visby-cf": "Visby CF",
  "Trade-regular": "Trade Gothic LT Std",
  "Trade-bold": "Trade Gothic Bold Std",
  "Trade-cond": "Trade Gothic CondEighteen Std",
  "Trade-standard":"Trade Gothic LT Stds",
  "Trade-standard-bold":"Trade Gothic Bold Stds"
);

@function font_fam($font_fam) {
  @return map-get($fonts_family, $font_fam);
}

@each $fonts, $font_val in $fonts_family {
  .font-#{$fonts} {
    font-family: $font_val !important;
  }
  @media (max-width: 767.98px) {
    .font-sm-#{$fonts} {
      font-family: $font_val !important;
    }
  }
}
.Vcf_bold {
  font-family: "visby_cfextra_bold" !important;
}
.pn_lt {
  font-family: "Proxima Nova Lt" !important;
}
.Rale {
  font-family: "Raleway" !important;
}

// New Fonts
@font-face {
  font-family: "Raleway";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Bold.eot");
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Bold.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Bold.woff")
      format("woff"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Bold.ttf")
      format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Raleway";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-ExtraBold.eot");
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-ExtraBold.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-ExtraBold.woff")
      format("woff"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-ExtraBold.ttf")
      format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Raleway";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Light.eot");
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Light.eot?#iefix")
      format("embedded-opentype"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Light.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Light.woff")
      format("woff"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Light.ttf")
      format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Raleway";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Medium.eot");
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Medium.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Medium.woff")
      format("woff"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Medium.ttf")
      format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Raleway";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-SemiBold.eot");
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-SemiBold.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-SemiBold.woff")
      format("woff"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-SemiBold.ttf")
      format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Raleway";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Regular.eot");
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Regular.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Regular.woff")
      format("woff"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Regular.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Visby CF";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-Regular.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-Regular.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Visby CF";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-Thin.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-Thin.woff")
      format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Visby CF";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-Light.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-Light.woff")
      format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Visby CF";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-Medium.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-Medium.woff")
      format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Visby CF Demi";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-DemiBold.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-DemiBold.woff")
      format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Visby CF Extra";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-ExtraBold.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-ExtraBold.woff")
      format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Visby CF";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-Bold.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-Bold.woff")
      format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Visby CF";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-Heavy.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-Heavy.woff")
      format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Clarendon LT Std";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Clarendon/ClarendonLTStd-Bold.eot");
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Clarendon/ClarendonLTStd-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Clarendon/ClarendonLTStd-Bold.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Clarendon/ClarendonLTStd-Bold.woff")
      format("woff"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Clarendon/ClarendonLTStd-Bold.ttf")
      format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Clarendon LT Std";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Clarendon/ClarendonLTStd.eot");
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Clarendon/ClarendonLTStd.eot?#iefix")
      format("embedded-opentype"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Clarendon/ClarendonLTStd.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Clarendon/ClarendonLTStd.woff")
      format("woff"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Clarendon/ClarendonLTStd.ttf")
      format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Clarendon LT Std";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Clarendon/ClarendonLTStd-Light.eot");
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Clarendon/ClarendonLTStd-Light.eot?#iefix")
      format("embedded-opentype"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Clarendon/ClarendonLTStd-Light.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Clarendon/ClarendonLTStd-Light.woff")
      format("woff"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Clarendon/ClarendonLTStd-Light.ttf")
      format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Marydale;
  src: url("../../fonts/Marydale.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Trade Gothic LT Std";
  src: url("../../fonts/TradeGothic-LT-Regular/TradeGothic-LT-Regular.ttf");
}
@font-face {
  font-family: "Trade Gothic Bold Std";
  src: url("../../fonts/TradeGothic-LT-Bold/TradeGothic-LT-Bold.ttf");
}
@font-face {
  font-family: "Trade Gothic CondEighteen Std";
  src: url("../../fonts/TradeGothic-LT-CondEighteen-Bold/TradeGothic-LT-CondEighteen-Bold.ttf");
}

@font-face {
  font-family: "Trade Gothic CondEighteen Std";
  src: url("../../fonts/TradeGothic-LT-CondEighteen-Bold/TradeGothic-LT-CondEighteen-Bold.ttf");
}

@font-face {
  font-family: 'Trade Gothic LT Stds';
  src: url('../../../src/fonts/trade-gothic-lt-std.otf');
}

@font-face {
  font-family: 'Trade Gothic Bold Stds';
  src: url('../../../src/fonts/trade-gothic-lt-std-bold.otf');
}

@font-face {
  font-family: "FreightDispCmp Pro";
  src: url("../../fonts/freightFont/zupasfr8italic-webfont.woff") format("woff");
}

@font-face {
  font-family: "freightdispcmp-pro";
  src: url("https://use.typekit.net/af/2baf1e/000000000000000077359fb9/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/2baf1e/000000000000000077359fb9/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/2baf1e/000000000000000077359fb9/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

// adobe fonts


@font-face {
  font-family: "trade-gothic-next";
  src: url("https://use.typekit.net/af/e7065f/00000000000000007735ba3f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/e7065f/00000000000000007735ba3f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/e7065f/00000000000000007735ba3f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "trade-gothic-next";
  src: url("https://use.typekit.net/af/a3a085/00000000000000007735ba73/30/l?subset_id=2&fvd=n3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/a3a085/00000000000000007735ba73/30/d?subset_id=2&fvd=n3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/a3a085/00000000000000007735ba73/30/a?subset_id=2&fvd=n3&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: "trade-gothic-next-condensed";
  src: url("https://use.typekit.net/af/f060c3/00000000000000007735ba43/30/l?subset_id=2&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/f060c3/00000000000000007735ba43/30/d?subset_id=2&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/f060c3/00000000000000007735ba43/30/a?subset_id=2&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "trade-gothic-next-condensed";
  src: url("https://use.typekit.net/af/40e706/00000000000000007735ba54/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/40e706/00000000000000007735ba54/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3")
      format("woff"),
    url("https://use.typekit.net/af/40e706/00000000000000007735ba54/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 800;
  font-stretch: normal;
}

@font-face {
  font-family: "freightdispcmp-pro";
  src: url("https://use.typekit.net/af/aac473/000000000000000077359fb4/30/l?subset_id=2&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/aac473/000000000000000077359fb4/30/d?subset_id=2&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/aac473/000000000000000077359fb4/30/a?subset_id=2&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "freightdispcmp-pro";
  src: url("https://use.typekit.net/af/2baf1e/000000000000000077359fb9/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/2baf1e/000000000000000077359fb9/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/2baf1e/000000000000000077359fb9/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

.font-trade-gothic-next {
  font-family: "trade-gothic-next", sans-serif !important;
}
.font-trade-gothic-next-condensed {
  font-family: "trade-gothic-next-condensed", sans-serif !important;
}
.font-freightdispcmp-pro {
  font-family: "freightdispcmp-pro", serif !important;
}
