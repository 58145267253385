@import "styles/base/custom_bootstrap.scss";
@import "styles/base/_baseModule";

:root {
  --get-height: 600px;
  --slide-height: calc(var(--get-height) + 147px);
  @media (min-width: 1601px) {
    --slide-height: calc(var(--get-height) + 164px);
  }
  @include media-breakpoint-down(lg) {
    --slide-height: calc(var(--get-height) + 178px);
  }
  @include media-breakpoint-down(md) {
    --slide-height: calc(var(--get-height) + 245px);
  }
  @include media-breakpoint-down(sm) {
    --slide-height: calc(var(--get-height) + 263px);
  }
  @media (max-width: 480px) {
    --slide-height: calc(var(--get-height) + 259px);
  }
}

body {
  @include font_sty(
    16px,
    Pri_clr(lt-black) !important,
    $fs_family: font_fam(rale) !important
  );
}
.App {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.app_container_wrap_custom_span {
  @include font_sty(
    font(s20),
    Pri_clr(lt-black),
    font_fam(rale),
    $xxl_fs: font(s25),
    $md_fs: font(s19),
    $sm_fs: font(s18),
    $sm_lh: 24px,
    $fs_weight: 500
  );
}
.app_container_wrap {
  p {
    @include font_sty(
      font(s18),
      Pri_clr(lt-black),
      font_fam(rale),
      $sm_fs: font(s18),
      $sm_lh: 24px,
      $fs_weight: 500
    );
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include font_sty($fs_family: font_fam(Vcf));
  }
  .large_text {
    @include font_sty(
      font(s25),
      null,
      font_fam(Cls),
      $fs_lh: 28px,
      $xxl_fs: font(s28),
      $xxl_lh: 32px,
      $sm_fs: font(s23)
    );
  }
  .medium_text {
    @include font_sty(
      font(s25),
      $fs_lh: 32px,
      $sm_fs: font(s20),
      $sm_lh: 28px,
      $xs_fs: font(s18),
      $xs_lh: 23px,
      $fs_weight: 500
    );
  }
  .small_text {
    @include font_sty(
      font(s17),
      $fs_lh: normal,
      $sm_fs: font(s15),
      $fs_weight: normal,
      $xxl_fs: font(s20)
    );
  }
  h1 {
    @include font_sty(
      font(s50),
      $fs_lh: 50px,
      $fs_space: 2px,
      $sm_lh: 35px,
      $sm_fs: font(s32),
      $xxl_fs: font(s56),
      $xxl_lh: 56px,
      $xxl_space: 5px
    );
  }
  h2 {
    @include font_sty(
      font(s27),
      $fs_lh: 35px,
      $xxl_fs: font(s30),
      $xxl_lh: 41px,
      $sm_fs: font(s25),
      $sm_lh: 33px
    );
  }
  h3 {
    @include font_sty(
      font(s23),
      $fs_lh: 23px,
      $xxl_fs: font(s26),
      $xxl_lh: 26px,
      $sm_fs: font(s20),
      $xs_fs: font(18px)
    );
  }
  .h3 {
    @include font_sty(font(s48), $fs_lh: 53px, $sm_fs: font(s16), $sm_lh: 22px);
  }
  .fs_60 {
    @include font_sty(font(s60), $fs_lh: 60px, $sm_fs: font(s30), $sm_lh: 33px);
  }
  h4 {
    @include font_sty(
      font(s48),
      $fs_lh: 48px,
      $fs_space: 0.1em,
      $sm_fs: font(s30),
      $sm_lh: 33px
    );
  }
  .h4 {
    @include font_sty(
      font(s24),
      $fs_lh: 26.54px,
      $sm_fs: font(s16),
      $sm_lh: 22.4px
    );
  }
  h5 {
    @include font_sty(font(s30), $fs_space: 1.6px, $sm_lh: 110%);
  }
  .h5 {
    @include font_sty(font(s75), $fs_lh: 78px, $sm_fs: font(s30), $sm_lh: 33px);
  }
  h6 {
    @include font_sty(
      font(s30),
      $fs_lh: 33px,
      $fs_space: 1.6px,
      $sm_lh: 25.78px,
      $sm_fs: font(s22)
    );
  }
  .h6 {
    @include font_sty(
      font(s30),
      $fs_lh: 50px,
      $fs_space: 2px,
      $sm_lh: 25.78px,
      $sm_fs: font(s22),
      $xxl_fs: font(s56),
      $xxl_lh: 56px,
      $xxl_space: 5px
    );
  }
  .customh2 {
    @include font_sty(
      font(s75),
      $fs_lh: 78px,
      $xxl_fs: font(s75),
      $xxl_lh: 78px,
      $sm_fs: font(s30),
      $sm_lh: 33px
    );
  }
  .customcontactp {
    @include font_sty(font(s18), $fs_lh: 25px, $sm_fs: font(s16), $sm_lh: 20px);
  }
  .contactsub_title2 {
    @include font_sty(
      font(s30),
      $fs_weight: 400,
      $xxl_weight: 400,
      $fs_lh: 42px,
      $xxl_fs: font(s30),
      $xxl_lh: 42px,
      $sm_fs: font(s16),
      $sm_lh: 22px
    );
  }
  .contactsub_title3 {
    @include font_sty(
      font(s16),
      $fs_weight: 400,
      $xxl_weight: 400,
      $fs_lh: 22px,
      $xxl_fs: font(s16),
      $xxl_lh: 22px,
      $sm_fs: font(s16),
      $sm_lh: 22px
    );
  }
  .customtext-style2 {
    @include font_sty(
      font(s18),
      $fs_weight: 500,
      $fs_lh: 25px,
      $sm_fs: font(s16),
      $sm_lh: 20px
    );
  }
  .custombtn-large {
    @include font_sty(
      font(s16),
      $fs_weight: 800,
      $xxl_weight: 500,
      $fs_lh: 19px,
      $sm_fs: font(s16),
      $sm_lh: 19px
    );
  }
  .title_text {
    @include font_sty(
      font(s48),
      null,
      font_fam(Cls),
      $fs_lh: 48px,
      $fs_space: 0px
    );
  }
  ul {
    li {
      @include font_sty(
        font(s20),
        Pri_clr(lt-black),
        font_fam(rale),
        $xxl_fs: font(s25),
        $md_fs: font(s19),
        $sm_fs: font(s18),
        $sm_lh: 24px,
        $fs_weight: 500
      );
    }
    @include media-breakpoint-down(md) {
      padding-left: 24px;
      padding-top: 10px;
    }
  }
  // New design typography
  .mini-heading {
    @include font_sty(font(s18), $fs_space: 1.6px, $sm_lh: normal);
  }
  .d-md-grid {
    @include media-breakpoint-up(lg) {
      display: grid !important;
    }
  }
  .h-18 {
    @include font_sty(
      font(s18),
      null,
      font_fam(Cls),
      $fs_lh: normal,
      $fs_space: normal
    );
  }
  .h-30 {
    @include font_sty(
      font(s30),
      null,
      font_fam(Cls),
      $fs_lh: normal,
      $fs_space: normal
    );
  }
  .h-16 {
    @include font_sty(
      font(s16),
      null,
      $fs_lh: normal,
      $fs_space: normal,
      $sm_fs: font(s14)
    );
  }
  .p-18 {
    @include font_sty(
      font(s18),
      null,
      $fs_lh: normal,
      $fs_space: normal,
      $sm_fs: font(s16)
    );
  }
  .h-14 {
    @include font_sty(
      font(s14),
      null,
      $fs_lh: normal,
      $fs_space: normal,
      $sm_fs: font(s13)
    );
  }
  .p-16 {
    @include font_sty(font(s16), null, $fs_lh: normal, $fs_space: normal);
  }
  .lh-18 {
    line-height: 18.2px !important;
  }
  .h-48 {
    @include font_sty(
      font(s48),
      $fs_lh: normal,
      $fs_space: normal,
      $sm_fs: font(s24)
    );
  }
  .h-22 {
    @include font_sty(
      font(s22),
      $fs_lh: normal,
      $fs_space: 0.5px,
      $sm_fs: font(s18)
    );
  }
  .no-shadow {
    box-shadow: none !important;
  }
  .text-sm-uppercase {
    @include media-breakpoint-up(lg) {
      text-transform: uppercase;
    }
  }
  .w-sm-75 {
    @include media-breakpoint-up(lg) {
      width: 75% !important;
    }
  }
  .d-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .input-reset {
    background: transparent;
    border: none;
    outline: none !important;
    box-shadow: none !important;
    font-size: font(s20);
    color: #3d3935;
    font-family: "Raleway";
    font-weight: 500;
    padding: 0;
    width: max-content;
    height: auto;
  }
  .section_title1 {
    text-shadow: 0px 0px 14px #ffffff;
    text-transform: uppercase;
  }
  .sub_title1 {
    @include font_sty(
      font(s22),
      null,
      font_fam(Vcf),
      $sm_fs: font(s18),
      $sm_lh: 21.6px,
      $fs_weight: normal,
      $fs-sty: uppercase,
      $fs_space: 1px,
      $fs_lh: 25.78px
    );
  }
  .sub_title1_item {
    min-height: 52px;
    @media screen and (max-width: 767px) {
      min-height: auto;
    }
  }
  .sub_title2 {
    @include font_sty(
      font(s16),
      null,
      font_fam(Cls),
      $fs_lh: 19px,
      $fs_weight: normal
    );
  }
  .title_lg {
    @include font_sty(
      font(s24),
      null,
      font_fam(Cls),
      $fs_lh: 33.6px,
      $fs_weight: normal
    );
  }
  .overline_text {
    @include font_sty(font(s11), $fs_space: 2px, $fs_lh: normal);
  }
  .overline_lg_text {
    @include font_sty(
      font(s18),
      $fs_space: 2px,
      $fs_lh: normal,
      $sm_fs: font(s13)
    );
  }
  .optional_text {
    @include font_sty(
      font(s15),
      Sec_clr(text-grey),
      font-fam(rale),
      $sm_fs: font(s13),
      $fs_lh: normal,
      $fs_weight: 500,
      $fs-sty: lowercase
    );
  }
  .caption {
    @include font_sty(
      font(s15),
      Pri_clr(lt-black),
      font-fam(rale),
      $sm_fs: font(s13),
      $fs_lh: 15.26px,
      $fs_weight: 500,
      $fs_space: normal
    );
  }
  .text-style1 {
    @include font_sty(
      font(s16),
      Pri_clr(lt-black),
      font-fam(rale),
      $sm_fs: font(s14),
      $fs_lh: 19.9px,
      $fs_weight: 500,
      $fs_space: normal
    );
  }
  .text-style2 {
    @include font_sty(
      font(s18),
      Pri_clr(lt-black),
      font-fam(rale),
      $sm_fs: font(s16),
      $fs_lh: 25.45px,
      $sm_lh: 19.9px,
      $fs_weight: 500,
      $fs_space: normal
    );
  }
  .text-style-xs {
    @include font_sty(
      font(s14),
      Pri_clr(lt-black),
      font-fam(rale),
      $fs_lh: 20.3px,
      $fs_weight: 500,
      $fs_space: normal
    );
  }
  .my-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .my-md-40 {
    @include media-breakpoint-up(md) {
      margin-top: 40px !important;
      margin-bottom: 40px !important;
    }
  }
  .mb-40 {
    margin-bottom: 40px !important;
  }
  .mb-md-40 {
    @include media-breakpoint-up(lg) {
      margin-bottom: 40px !important;
    }
  }
  .mt-40 {
    margin-top: 40px !important;
  }
  .fs-14 {
    @include font_sty(font(s14) !important);
  }
  .fs-16 {
    @include font_sty(font(s16) !important);
  }
  .text_style1 {
    @include font_sty(font(s18), $sm_fs: font(s16));
  }
  .text-normal {
    text-transform: unset !important;
  }
  .ls-normal {
    letter-spacing: normal !important;
  }
  .ls-sm-normal {
    @include media-breakpoint-down(md) {
      letter-spacing: normal !important;
    }
  }
  .lh-normal {
    line-height: normal !important;
  }
  .ls-05 {
    letter-spacing: 0.5px !important;
  }
  .ls-1 {
    letter-spacing: 1px !important;
  }
  .lh-normal {
    line-height: normal !important;
  }
  .lh-md-normal {
    @include media-breakpoint-down(md) {
      line-height: normal !important;
    }
  }
  .lh-md-05 {
    @include media-breakpoint-down(md) {
      line-height: 0.5 !important;
    }
  }
  .text_underline {
    text-decoration: underline !important;
  }
  .border-underline {
    border-bottom: 1px solid Pri_clr(lt-black) !important;
  }
  .border-none {
    border: unset !important;
  }
  .no-underline {
    text-decoration: none !important;
  }
  .max-content {
    width: max-content !important;
    min-width: max-content !important;
  }
  .max-w-auto {
    max-width: 100% !important;
  }
  .text-md-nowrap {
    @include media-breakpoint-up(md) {
      white-space: nowrap;
    }
  }
  .mw-auto {
    min-width: auto !important;
  }
  .del-text {
    text-decoration: line-through !important;
    text-decoration-color: Pri-clr(dark-blue) !important;
    color: #afafaf !important;
    font-weight: 500;
  }
  .sm-w100 {
    @include media-breakpoint-down(md) {
      width: 100% !important;
      max-width: 100% !important;
      min-width: 100% !important;
    }
  }
  .sm-mx-0 {
    @include media-breakpoint-down(md) {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }
  .break-all {
    word-break: break-all;
  }
  .reset-text {
    line-height: normal;
    letter-spacing: normal;
  }
  .hover-clr-red {
    &:hover,
    &:focus,
    &:active {
      color: Sec_clr(dark-red) !important;
      @extend %Removed_extra_sty;
    }
  }
  .gap-3 {
    gap: 16px;
    .gap-item {
      flex: 0 0 calc(33.33333% - 8px);
      max-width: calc(33.33333% - 8px);
    }
  }
  .gap-4 {
    gap: 24px;
    .gap-item {
      flex: 0 0 calc(33.33333% - 16px);
      max-width: calc(33.33333% - 16px);
    }
    .col-sm-12 {
      &.gap-item {
        @include media-breakpoint-down(md) {
          flex: 0 0 calc(100%);
          max-width: calc(100%);
        }
      }
    }
    .col-md-6 {
      &.gap-item {
        @include media-breakpoint-up(md) {
          flex: 0 0 calc(50% - 12px);
          max-width: calc(50% - 12px);
        }
      }
    }
    .col-lg-4 {
      &.gap-item {
        @include media-breakpoint-up(lg) {
          flex: 0 0 calc(33.33333% - 16px);
          max-width: calc(33.33333% - 16px);
        }
      }
    }
    &.gap-md-4 {
      @include media-breakpoint-down(md) {
        gap: 0 !important;
      }
    }
  }
  .gap-md-3 {
    @include media-breakpoint-up(lg) {
      gap: 16px;
      .gap-item {
        flex: 0 0 calc(33.33333% - 8px);
        max-width: calc(33.33333% - 8px);
      }
    }
  }
  .text-style-none {
    text-decoration: none;
    letter-spacing: normal;
    line-height: normal;
  }
  .text-transform-none {
    text-transform: none !important;
  }
  .custom_w {
    @include media-breakpoint-up(lg) {
      width: 96%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .px-sm-only-4 {
    @include media-breakpoint-down(md) {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
  .card_grid_wrapper {
    &.home_screen {
      .customh2 {
        @media (max-width: 1025px) and (min-width: 768px) {
          font-size: font(s55);
          line-height: 60px;
        }
      }
      .h4 {
        font-size: font(s18);
        line-height: 22.54px;
      }
    }
  }
}

.display_cta > a + a {
  margin-left: 6px;
}
.carousel .slide {
  background: white !important;
  overflow: hidden;
}
.slider,
.slider-wrapper {
  height: auto !important;
}
// Theme buttons
.btn-small {
  @include btn_theme(font_clr(sb-dark), 50px, Pri_clr(chathams-blue));
  @extend %btn-small;
  &.outline {
    background-color: transparent !important;
    color: Pri_clr(dark-blue) !important;
    &:hover,
    &:focus,
    &:active,
    &:disabled {
      color: Sec-clr(sec-white);
    }
  }
}
.btn-small.red {
  @include btn_theme(font_clr(red-dark), 50px, Sec_clr(red-devil));
  @extend %btn-small;
  &.outline {
    background-color: transparent;
    color: font_clr(red-dark);
    &:hover,
    &:focus,
    &:active {
      color: Sec-clr(sec-white);
    }
  }
}
.btn-small.green {
  @include btn_theme(font_clr(green-clr), 50px, Pri_clr(chalet-green));
  @extend %btn-small;
}
.btn-small.dark-grey {
  @include btn_theme(font_clr(dark-grey), 50px, Pri_clr(cod-grey));
  @extend %btn-small;
  &.outline {
    background-color: transparent;
    color: font_clr(dark-grey);
    &:hover,
    &:focus,
    &:active {
      color: Sec-clr(sec-white);
    }
  }
}
.btn-text {
  @include font_sty(
    font(s16),
    Pri_clr(lt-black),
    font_fam(Vcf),
    $fs-sty: uppercase
  );
  padding: 0;
  display: inline-block;
  @extend %Removed_extra_sty;
  &:hover,
  &:focus,
  &:active {
    color: Pri_clr(dark-blue);
  }
}
.btn-large {
  @include btn_theme(Pri_clr(dark-blue), 60px, Pri_clr(prussian-blue));
  @extend %btn-large;
  &.outline {
    background-color: transparent !important;
    color: Pri_clr(dark-blue) !important;
    &:hover,
    &:focus,
    &:active,
    &:disabled {
      color: Sec-clr(sec-white);
    }
  }
  &.xl {
    width: 100%;
    max-width: 500px;
    @include media-breakpoint-down(md) {
      width: 60%;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
  &.sm-small {
    @include media-breakpoint-down(sm) {
      max-width: 156px;
    }
  }
}
.btn-large.red {
  @include btn_theme(font_clr(red-dark), 60px, Sec_clr(red-devil));
  @extend %btn-large;
  &.outline {
    background-color: transparent;
    color: font_clr(red-dark);
    &:hover,
    &:focus,
    &:active {
      color: Sec-clr(sec-white);
    }
  }
}
.btn-large.green {
  @include btn_theme(Pri_clr(highland-green), 60px, Pri_clr(chalet-green));
  @extend %btn-large;
  &.outline {
    background-color: transparent;
    color: Pri_clr(highland-green);
    &:hover,
    &:focus,
    &:active {
      color: Sec-clr(sec-white);
    }
  }
}
.btn-large.light-green {
  @include btn_theme(Pri_clr(light-green), 60px, Pri_clr(chalet-green));
  @extend %btn-large;
  &.outline {
    background-color: transparent;
    color: Pri_clr(highland-green);
    &:hover,
    &:focus,
    &:active {
      color: Sec-clr(sec-white);
    }
  }
}
.btn-large.dark-grey {
  @include btn_theme(Pri_clr(lt-black), 60px, Pri_clr(cod-grey));
  @extend %btn-large;
  &.outline {
    background-color: transparent;
    color: Pri_clr(lt-black);
    &:hover,
    &:focus,
    &:active {
      color: Sec-clr(sec-white);
    }
  }
}
.btn-large.btn-white {
  @include btn_theme(
    Sec_clr(sec-white),
    60px,
    Sec_clr(murcury-clr),
    Pri_clr(lt-black),
    Pri_clr(lt-black)
  );
  @extend %btn-large;
}
.btn-x-large {
  @include btn_theme(font_clr(sb-dark), 0);
  @extend %btn-x-large;
}
.btn-x-large.red {
  @include btn_theme(font_clr(red-dark), 0);
  @extend %btn-x-large;
}
.btn-x-large.green {
  @include btn_theme(font_clr(green-clr), 0);
  @extend %btn-x-large;
}
.btn-x-large.dark-grey {
  @include btn_theme(font_clr(dark-grey), 0);
  @extend %btn-x-large;
  &.outline {
    background-color: transparent;
    color: Pri_clr(dark-grey);
    &:hover,
    &:focus,
    &:active {
      color: Sec-clr(sec-white);
    }
  }
}
.btn-disabled-grey {
  &.btn-large {
    &:disabled,
    &.btn-large {
      cursor: not-allowed !important;
      background-color: Sec_clr(medium-grey) !important;
      border-color: Sec_clr(medium-grey) !important;
      &:hover,
      &:focus,
      &:active {
        background-color: Sec_clr(medium-grey) !important;
        border-color: Sec_clr(medium-grey) !important;
      }
    }
  }
}
.button_2 {
  @include font_sty(font(s16), Pri_clr(lt-black), font-fam(Vcf), $fs_lh: 19px);
  background: transparent !important;
  outline: none !important;
  box-shadow: none !important;
  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    color: Pri_clr(lt-black);
  }
}
.header-menu-font {
  &:focus-visible {
    outline: unset;
  }
  @include media-breakpoint-down(md) {
    @include font_sty(
      font(s15),
      null,
      font_fam(Vcf),
      $xxl_fs: font(s18),
      $fs_lh: 1,
      $sm_fs: font(s16)
    );
    border-bottom: 1px solid #e1e1e1;
    letter-spacing: 1px;
    line-height: 25.78px;
    padding: 16px 0;
    margin: 0 24px;
    width: calc(100% - 48px);
    font-weight: 600;
  }
  &:hover,
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}
#basic-navbar-nav {
  text-align: left;
  flex-grow: 0;
  @include media-breakpoint-down(md) {
    position: fixed;
    // top: 170px;
    z-index: 999;
    background: #fff;
    width: 100%;
    height: calc((var(--window_height, 1vh) * 100) - 100px);
    left: 0;
    box-shadow: 0px 10px 10px #0000003d;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: auto;
    &.collapse:not(.show) {
      display: none;
    }
  }
}
.no-track-basic-navbar {
  @include media-breakpoint-down(md) {
    top: 110px !important;
    padding-top: 20px;
  }
}
.with-track-basic-navbar {
  @include media-breakpoint-down(md) {
    top: 150px !important;
    padding-top: 20px;
  }
}
.cta_button {
  @include theme_button;
  @extend %theme_btn_style;
}
.cta_button.color-sky-blue-light {
  @include theme_button($sky_blue_light);
  @extend %theme_btn_style;
}
.font-cltld {
  font-family: "Clarendon LT Std";
}
.App {
  overflow: hidden;
}
.carousel_images {
  object-fit: contain !important;
}
.main_logo_div {
  position: relative;
  @media (min-width: 992px) {
    margin-top: 13px;
  }
}
.nav_wrapper_main_menu {
  position: relative;
  padding: 8px 0;
}
.embed-wrapper {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  &::before {
    display: block;
    content: "";
    padding-top: 100%;
  }
  .embed-responsive-item {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
.carousel-status {
  display: none;
}
.textAlignLeft {
  text-align: left;
}
.carousel .control-dots .dot {
  box-shadow: unset !important;
  background: #d5d7d8 !important;
  border-radius: 50% !important;
  width: 10px !important;
  height: 10px !important;
}
.item-carousel-wrap {
  .carousel.carousel-slider {
    padding-bottom: 20px;
    .control-dots {
      background: transparent;
      position: static;
      margin-bottom: 0;
      margin-top: 0;
      @include media-breakpoint-down(lg) {
        margin-top: 10px;
      }
    }
    .control-arrow {
      background: url("#{$REACT_APP_CLOUD_FRONT}/images/Home/item-carousel-icons.svg")
        no-repeat !important;
      width: 25px;
      height: 25px;
      display: inline-block;
      left: 50%;
      transform: translateX(-50%);
      bottom: 17px;
      top: auto;
      opacity: 1;
      margin-left: 70px;
      &:before {
        content: none;
      }
      &:hover {
        background-color: transparent;
      }
    }
    .control-arrow.control-prev {
      transform: translateX(-50%) rotate(180deg);
      margin-left: -70px;
    }
  }
}
.carousel .control-dots .dot:focus {
  outline: none !important;
}
.color-orange.menu-section.container,
.color-sky-blue-light.bccolor-sky-blue-light.menu-section.container {
  align-items: center;
  justify-content: center;
  padding: 50px 20px 50px 20px;
  text-align: left;
  max-width: 800px;
}
.rewards-details-wrapper {
  .menu-section.container {
    @include media-breakpoint-down(sm) {
      padding-bottom: 50px;
    }
  }
}
.content_center_box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mb70 {
  margin-bottom: 70px;
}
@media (max-width: 450px) {
  .display_cta {
    display: flex;
  }
}
.navbar-toggler {
  &.collapsed {
    margin: 0;
    border: none;
    padding: 0;
    outline: none;
    border: none;
    box-shadow: none;
    &:focus {
      @extend .collapsed;
    }
  }
}
.navbar-expand-md {
  &.navbar-light {
    .navbar-toggler {
      &:hover,
      &:focus {
        background: none;
        outline: none;
        border: none;
        box-shadow: none;
      }
    }
  }
}

.add-sticky {
  box-shadow: 0 0 20px #0000003d;
}
.sticky-logo {
  height: 50px;
  // margin-left: 220px;
  @include media-breakpoint-down(md) {
    margin-top: 0;
    width: 100px;
    height: 30px;
    margin-left: 50px;
  }
  @media (max-width: 370px) {
    width: 90px;
  }
}
.MuiIconButton-root:hover {
  background-color: white !important;
}
.MuiIconButton-root:focus {
  outline: none !important;
}
.MuiIconButton-root {
  padding: 0px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.MuiCardActions-root {
  padding: 0px !important;
}
.navbar-toggler-icon:focus,
.navbar-toggler:focus {
  outline: none;
}
.footer-font.links {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: table;
  z-index: 0;
  &::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 16px;
    width: 0;
    height: 1px;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
    z-index: -1;
    @include media-breakpoint-down(mb) {
      left: 0;
    }
  }
  &:hover:after,
  &:focus:after,
  &:focus-visible:after {
    width: calc(100% - 32px);
    outline: unset;
    @include media-breakpoint-down(mb) {
      width: 0;
    }
  }
  &:focus-visible {
    outline: unset;
  }
}
.menu_categories_wrapper {
  position: relative;
  &::before {
    content: "";
    position: fixed;
    background: #fff;
    width: 100vw;
    left: 0;
    top: calc(var(--get-nav-height) + 13px);
    height: 0;
    z-index: 1;
    transition: all 0.3s ease-in-out;
    @include media-breakpoint-down(md) {
      height: 0;
    }
  }
  .theme_breadcrumbs_style {
    z-index: 9;
    position: fixed;
    top: calc(var(--get-nav-height) + 80px);
    margin: 0;
    padding: 0;
    @include media-breakpoint-down(md) {
      top: calc(var(--get-nav-height) + 25px);
    }
  }
  .menu_tabs_wrap {
    position: fixed;
    top: calc(var(--get-nav-height) + 85px);
    padding-top: 0;
    padding-bottom: 0;
    z-index: 1;
    transition: all 0.3s ease-in-out;
    @include media-breakpoint-down(md) {
      // max-width: calc(100vw - 30px);
      left: 0px;
    }
    @include media-breakpoint-down(md) {
      top: calc(var(--get-nav-height) + 70px) !important;
    }
    @media (min-width: 768px) and (max-width: 1180px) {
      top: calc(var(--get-nav-height) + 84px) !important;
    }
    &.red {
      .nav-link {
        border: 1px solid #767676 !important;
        &.active,
        &:hover,
        &:focus,
        &:focus-visible {
          color: Sec_clr(sec-white) !important;
          background-color: Pri_clr(dark-blue);
          border-color: Pri_clr(dark-blue) !important;
          outline: unset;
        }
      }
    }
    &.blue {
      .nav-link {
        border-radius: 25px !important;
        border: 1px solid #767676;
        min-width: auto;
        white-space: nowrap;
        font-size: 1.125rem;
        color: #3d3935;
        line-height: 1.1;
        @include media-breakpoint-down(md) {
          box-shadow: 1px 1px 5px #c0c0c0de;
          border: 0;
        }
        &.active,
        &:hover {
          color: Sec_clr(sec-white) !important;
          background-color: Pri-clr(dark-blue);
          border-color: Pri-clr(dark-blue) !important;
          box-shadow: none;
        }
      }
    }
  }
  .tab-content {
    padding-bottom: 0;
    .menus_pane,
    .try2_pane_wrap:not(:first-child) {
      padding-top: 250px;
      &.menus_pane_with_track_bar,
      .try2_pane_wrap:not(:first-child) {
        padding-top: 290px !important;
        @include media-breakpoint-down(md) {
          padding-top: 255px !important;
        }
      }
    }

    [aria-labelledby="left-tabs-example-tab-Try 2 Combo"]:not(
        .no-menu-category-bar
      ) {
      padding-top: 145px !important;
      @include media-breakpoint-down(md) {
        padding-top: 140px !important;
      }
      @include media-breakpoint-up(sm) {
        .theme-spinner {
          div {
            svg {
              width: 70%;
              max-width: 290px;
            }
          }
        }
      }
    }
    @include media-breakpoint-down(md) {
      padding-bottom: 90px;
      .menus_pane,
      .try2_pane_wrap:not(:first-child) {
        padding-top: 140px;
        @include media-breakpoint-down(md) {
          padding-top: 220px;
        }
      }
      [aria-labelledby="left-tabs-example-tab-Try 2 Combo"] {
        .try2_pane_wrap:not(:first-child) {
          padding-top: 110px;
        }
      }
    }
    [aria-labelledby="left-tabs-example-tab-Try 2 Combo"].no-menu-category-bar {
      padding-top: 30px !important;
      @include media-breakpoint-down(md) {
        padding-top: 0px !important;
      }
    }
  }
  .no-track-heading {
    .menu_cate_title {
      @media (max-width: 767.98px) {
        top: calc(var(--get-nav-height) + 20px) !important;
      }
    }
    .filters_btns {
      @media (max-width: 767.98px) {
        top: calc(var(--get-nav-height) + 20px) !important;
      }
    }
    .menu_tabs_wrap {
      @media (max-width: 767.98px) {
        top: calc(var(--get-nav-height) + 50px) !important;
      }
    }
  }
  .menu_cate_title {
    position: fixed;
    top: calc(var(--get-nav-height) + 52px);
    z-index: 0;
    transition: all 0.3s ease-in-out;
    @include media-breakpoint-down(md) {
      top: calc(var(--get-nav-height) + 40px) !important;
    }
  }
  &.static_tabs_position {
    &::before {
      content: unset;
    }
    .tab-content {
      padding-top: 20px;
    }
    .menu_tabs_wrap {
      position: static;
    }
  }
  &.search-active {
    overflow: hidden;
    height: calc(100vh + var(--get-nav-height));
    &.menu_categories_wrapper {
      &::before {
        height: var(--get-nav-height);
        box-shadow: 0px 0px 10px rgba(61, 57, 53, 0.2);
        @include media-breakpoint-down(md) {
          height: auto;
        }
      }
    }
  }
  &.menu-page {
    .tab-content {
      @include media-breakpoint-down(md) {
        padding-bottom: 0;
      }
    }
  }
  &.catering-menu {
    .menu_cate_title {
      top: calc(var(--get-nav-height) + 110px);
    }
    .menu_tabs_wrap {
      top: calc(var(--get-nav-height) + 135px);
      height: 55px;
      max-width: calc(100vw - 48px);
      width: calc(100vw - 48px);
    }
    .tab-content {
      padding-top: 210px;
    }
    @media (min-width: 767px) and (max-width: 1250px) {
      .menuCatering_menu_start_order_btn__3cLO8 {
        top: calc(var(--get-nav-height) + 79px);
        right: 30px;
      }
    }
    @include media-breakpoint-down(md) {
      .menu_cate_title {
        top: calc(var(--get-nav-height) + 50px) !important;
      }
      .menu_tabs_wrap {
        top: calc(var(--get-nav-height) + 104px);
      }
      .tab-content {
        padding-top: 180px;
      }
    }
    ::before {
      content: "";
      position: fixed;
      background: red;
      width: 100vw;
      left: 0;
      top: 162px;
      top: calc(var(--get-nav-height) + 1px);
      height: 0;
      z-index: 10;
      transition: all 0.3s ease-in-out;
    }
  }
}
.menu_categories_wrapper {
  &.add-sticky {
    &::before {
      height: 88px;
      top: calc(var(--get-nav-height) + 44px) !important;
      box-shadow: 0px 0px 11px #3d393580;
      @include media-breakpoint-down(md) {
        height: 69px;
        top: calc(var(--get-nav-height) + 12px) !important;
      }
      @media (min-width: 768px) and (max-width: 1180px) {
        top: calc(var(--get-nav-height) + 38px) !important;
      }
    }
    box-shadow: none;
    .menu_tabs_wrap {
      top: calc(var(--get-nav-height) + 46px) !important;
      padding-top: 15px;
      height: 70px;
      @include media-breakpoint-down(md) {
        padding-top: 14px;
        top: 0;
        top: calc(var(--get-nav-height) + 13px) !important;
      }
      @media (min-width: 768px) and (max-width: 1180px) {
        padding-top: 40px;
      }
    }
    .menu_cate_title,
    .filters_btns {
      display: none;
    }
    .theme_breadcrumbs_style {
      display: none;
    }
    .menu_start_order_btn {
      top: calc(var(--get-nav-height) + 75px);
      @media (max-width: 1250px) and (min-width: 768px) {
        top: calc(var(--get-nav-height) + 61px);
      }
      @media (max-width: 1250px) {
        top: calc(var(--get-nav-height) + 14px);
      }
    }
    .search-bar-wrap {
      @include media-breakpoint-down(md) {
        top: 175px !important;
      }
    }
  }
}


.add-sticky.track-here.menu_categories_wrapper {
  &::before {
    top: 208px !important;
  }
  .menu_tabs_wrap {
    top: calc(var(--get-nav-height) + 96px) !important;
  }
  @include media-breakpoint-down(md) {
    &::before {
      top: 162px !important;
    }
    .menu_tabs_wrap {
      top: 160px !important;
      @supports (-webkit-touch-callout: none) {
        top: 160px !important;
      }
    }
  }
}
.no-track-here {
  &::before {
    top: calc(var(--get-nav-height) + 35px) !important;
  }
}
.add-sticky.no-track-here {
  &::before {
    top: calc(var(--get-nav-height) + 33px) !important;
    @media (max-width: 767.98px) {
      top: calc(var(--get-nav-height) - 39px) !important;
    }
  }
}

.track-here.menu_categories_wrapper {
  h1 {
    top: calc(var(--get-nav-height) + 100px) !important;
    @include media-breakpoint-down(md) {
      top: calc(var(--get-nav-height) + 60px) !important;
    }
  }
  .filters_btns {
    top: calc(var(--get-nav-height) + 60px) !important;
  }
  .menu_tabs_wrap {
    top: calc(var(--get-nav-height) + 130px) !important;
    @include media-breakpoint-down(md) {
      top: calc(var(--get-nav-height) + 90px) !important;
    }
  }
  .theme_breadcrumbs_style {
    top: calc(var(--get-nav-height) + 100px);
    @include media-breakpoint-down(md) {
      top: calc(var(--get-nav-height) + 22px);
    }
  }
}
// Tooltips Style
.tooltip {
  margin-top: 6px;
  .tooltip-inner {
    @include font_sty(font(s13), font_clr(white-clr) !important, "Raleway");
    background-color: #fff;
    border: none;
    border-radius: 0;
    padding: 2px 10px;
  }
  &.custom_tooltip {
    .tooltip-inner {
      color: #6f6f6f !important;
      font-weight: 500;
      background-color: #eee;
      border-radius: 4px;
      max-width: 100%;
      padding: 5px 10px;
    }
    .arrow {
      &::before {
        border-top-color: #eee;
      }
    }
  }
  &.show {
    opacity: 1;
  }
  &.tooltip-veg {
    @include toolTip_color(Sec_clr(dark-green));
  }
  &.tooltip-keto {
    @include toolTip_color(Sec_clr(lt-orng));
  }
  &.tooltip-low {
    @include toolTip_color(Pri_clr(dark-blue));
  }
  &.tooltip-high {
    @include toolTip_color(Sec_clr(text-grey));
  }
  &.tooltip-dairy {
    @include toolTip_color(Sec_clr(dark-red));
  }
}
.tooltip-icon {
  width: auto;
  height: 15px;
  margin-left: 8px;
  height: 18px;
  margin-top: 0px;
  margin-bottom: 5px;
  @media (max-width: 991px) {
    margin-top: 3px;
  }
  @media (max-width: 767px) {
    margin-top: 0px;
  }
}
.item-details-modal {
  .modal-dialog {
    height: 92vh;
    max-height: 92vh;
    max-width: 50%;
    @include media-breakpoint-up(md) {
      min-width: 500px;
    }
    .modal-content {
      transform: scale(0.8);
      transition: all 1s ease-in-out;
      height: 100%;
      .modal-header {
        border: none !important;
        text-align: left;
        padding: 0;
        .close span {
          display: none;
        }
      }
      .modal-body {
        padding: 0;
        padding-bottom: 10px;
        height: 100%;
      }
      .modal-header .close {
        padding: 0;
        margin: 15px 15px 0;
        background: url("#{$REACT_APP_CLOUD_FRONT}/images/Menu/close-icon.svg")
          no-repeat center;
        background-size: 20px;
        height: 20px;
        width: 20px;
        border: none !important;
        box-shadow: none !important;
        outline: none !important;
        opacity: 0.2;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
      }
    }
    @include media-breakpoint-down(sm) {
      height: auto;
    }
  }
}
.kids-combo-modal {
  .modal-dialog {
    @media (max-width: 980px) {
      max-width: 100%;
    }
  }
}
.item-details-modal.show-modal {
  .modal-content {
    animation: fadeIn 0.5s forwards;
  }
}
@keyframes fadeIn {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.item-details-modal.modal_close {
  .modal-content {
    animation: fadeOut 0.5s forwards;
  }
}
@keyframes fadeOut {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.8);
    opacity: 0;
  }
}
// Custom Scrollbar Style
.ps__rail-y,
.ps__rail-x {
  opacity: 1 !important;
}
.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  background-color: #d1d3d4 !important;
  border-radius: 10px !important;
  width: 10px !important;
}
.ps__thumb-y {
  background-color: #939598 !important;
  border-radius: 10px !important;
  width: 10px !important;
  right: 0 !important;
}
@media (max-width: 1600px) {
  .ps--active-x > .ps__rail-x,
  .ps--active-y > .ps__rail-y,
  .ps__thumb-y {
    width: 8px !important;
  }
}
@media (max-width: 768px) {
  .item-details-modal .modal-dialog {
    max-width: calc(100% - 30px);
    margin-left: 15px;
  }
}
.range-slider__wrap {
  width: 100% !important;
}
.location-info-box {
  .dropdown-menu {
    a {
      @include font_sty(font(s15), #616161, $fs_lh: 18px);

      &:hover,
      &:focus,
      &:focus-visible,
      .active,
      &:active {
        background-color: #f8f9fa !important;
        color: font_clr(g-grey);
      }
    }
  }
}
.dropdown_sub {
  .dropdown-menu {
    position: static;
    margin: 0;
    border-radius: 0;
    border: none;
    border-top: 1px solid #d5d5d5;
    margin-top: 8px;
    padding-bottom: 0;
    a {
      color: #979797;
      font-size: font(s14);

      &:hover,
      &:focus,
      &:focus-visible,
      .active,
      &:active {
        background-color: #f8f9fa !important;
        color: #86a179;
      }
    }
  }
}
.dropdown-menu {
  font-family: "Raleway";
}
.content_center_box.main_logo_div.d-md-none {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.error-msg-wrap {
  color: #df0707;
  bottom: 0;
  font-size: font(s12);
  left: 15px;
  line-height: 10px;
}
.form-with-validations .col-md-12.col-sm-12 {
  padding-bottom: 7px;
}
.input-date-picker::-webkit-calendar-picker-indicator {
  right: 0;
  position: absolute;
  width: 100%;
  height: 25px;
  opacity: 1;
}
.input-date-picker::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.input-date-picker::-webkit-clear-button {
  margin-right: 75px;
}
input[type="date"] {
  text-transform: uppercase;
}
// Custom React Select
.custom_select {
  .input_error {
    .react-select__control {
      color: Sec_clr(dark-red);
      border: 1px solid Sec_clr(dark-red);
    }
  }
  .react-select__control {
    background: transparent !important;
    border-radius: 5px;
    // height: 44px;
    border: none;
    box-shadow: inset 0px 0px 6px #44444440;
    @include font_sty(
      font(s14),
      Pri_clr(lt-black),
      $sm_fs: font(s13),
      $sm_lh: 15px,
      $fs_weight: 500
    );
    outline: none !important;
    padding: 0.375rem 0.75rem;
    min-height: 25px;
    min-height: 51px;
    margin-bottom: 0;
    .react-select__placeholder {
      color: #9d9c9a;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      font-family: font_fam(Visby-cf);
      font-weight: 500;
    }
    .react-select__value-container {
      padding: 0;
      padding-right: 8px;
      line-height: 17px;
    }
    .react-select__indicator {
      padding: 0 0 0 10px;
      &:before {
        content: "";
        border: none;
        background: url("./images/dropdown-icon.png") no-repeat;
        width: 9px;
        height: 14px;
        background-size: contain;
      }
      svg {
        display: none;
      }
      &.react-select__clear-indicator {
        display: none;
      }
    }
    .react-select__input input {
      color: Pri_clr(lt-black) !important;
      text-transform: uppercase;
      font-size: font(s14);
      font-family: "Proxima Nova Lt";
    }
    .react-select__indicator-separator {
      display: none;
    }
  }
  .react-select__indicators {
    background-color: #fff;
    z-index: auto;
  }
  .react-select__value-container {
    &.react-select__value-container--is-multi {
      overflow-y: auto;
      height: auto;
      padding-right: 0;
      width: calc(100% + 17px);
      margin-right: -11px;
    }
  }
}

// custom-select-2
.custom_select_2 .react-select__control {
  background: transparent !important;
  border: none;
  border-bottom: 2px solid #fff;
  border-radius: 0;
  outline: none !important;
  color: #fff !important;
  font-size: font(s14);
  font-family: "Proxima Nova Lt";
  box-shadow: none !important;
  text-transform: uppercase;
  padding: 0;
  min-height: 25px;
  margin-bottom: 0;
  &:hover {
    border-color: #fff;
  }
  .react-select__single-value {
    color: #fff;
  }
  .react-select__placeholder {
    color: rgba(255, 255, 255, 0.548);
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  .react-select__value-container {
    padding: 0;
    padding-right: 8px;
    line-height: 17px;
  }
  .react-select__indicator {
    padding: 0px 8px;
    &:before {
      content: "";
      border: 9px solid transparent;
      border-top-color: rgba(255, 255, 255, 0.548);
      border-radius: 3px;
      border-bottom: 0;
      @include media-breakpoint-down(sm) {
        border-width: 7px;
      }
    }
    svg {
      display: none;
    }
  }
  .react-select__input input {
    color: #fff !important;
    text-transform: uppercase;
    font-size: font(s14);
    font-family: "Proxima Nova Lt";
  }
  .react-select__indicator-separator {
    display: none;
  }
  @include media-breakpoint-down(sm) {
    height: 22px;
    margin-bottom: 17px;
    font-size: font(s11);
  }
}
.custom_select
  .react-select__control--menu-is-open
  .react-select__indicator:before {
  border-top-color: Pri_clr(lt-black) !important;
}
.custom_select {
  .react-select__option {
    font-size: font(s14);
    font-family: "Proxima Nova Lt";
    color: #333;
    padding: 4px 12px;
    text-align: left;
    cursor: pointer;
  }
  .react-select__option {
    &.react-select__option--is-focused,
    &.react-select__option--is-selected {
      background: #fff;
      color: Pri_clr(lt-black);
    }
  }
  .react-select__menu {
    box-shadow: 0px 4px 8px #44444440;
    border-radius: 9px;
    border: none;
    margin-top: 1px;
  }
  .react-select__menu-list {
    padding: 4px;
  }
}
.catering-order-wrap {
  .slider,
  .slider-wrapper,
  .hero-banner,
  .hero-banner .background {
    height: calc(100vh - var(--slide-height)) !important;
    min-height: 190px !important;
    @include media-breakpoint-down(lg) {
      min-height: 160px !important;
    }
    @include media-breakpoint-down(md) {
      min-height: 140px !important;
    }
    @include media-breakpoint-down(sm) {
      min-height: 100px !important;
    }
    .slide img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: left top;
    }
  }
}
.online-order-wrap {
  .slider,
  .slider-wrapper,
  .hero-banner,
  .hero-banner .background {
    height: calc(100vh - 478px) !important;
    min-height: 190px !important;
    @media (min-width: 1601px) {
      height: calc(100vh - 563px) !important;
    }
    @include media-breakpoint-down(lg) {
      min-height: 160px !important;
      height: calc(100vh - 509px) !important;
    }
    @include media-breakpoint-down(md) {
      min-height: 140px !important;
    }
    @include media-breakpoint-down(sm) {
      min-height: 100px !important;
    }
    .slide img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: left top;
    }
  }
}
.modal {
  z-index: 9999;
  padding: 0 !important;
}
.gm-style-iw-c {
  padding: 0 !important;
  max-height: max-content !important;
}
.gm-style-iw-d {
  padding: 0 !important;
  overflow: unset !important;
  max-height: max-content !important;
}
.gm-control-active[title="Zoom in"] {
  background: url("#{$REACT_APP_CLOUD_FRONT}/images/icons/plus-icon.svg")
    no-repeat !important;
  width: 37px !important;
  height: 37px !important;
  img {
    display: none !important;
    z-index: 999 !important;
  }
}
.gm-control-active[title="Zoom out"] {
  background: url("#{$REACT_APP_CLOUD_FRONT}/images/icons/minus-icon.svg")
    no-repeat !important;
  width: 37px !important;
  height: 37px !important;
  margin-top: -2px !important;
  img {
    display: none !important;
    z-index: 999 !important;
  }
}
.gm-svpc,
.gm-control-active.gm-fullscreen-control,
.gm-style-mtc {
  display: none !important;
}
.gm-style .gm-style-iw-c {
  max-width: 250px !important;
}
.gmnoprint {
  [draggable="false"] {
    background-color: transparent !important;
    box-shadow: none !important;
    &:first-child {
      margin-top: 40% !important;
    }
  }
}
.gm-ui-hover-effect {
  top: -3px !important;
  right: -3px !important;
  background: #fff !important;
  opacity: 1 !important;
  border-radius: 50% !important;
  width: 35px !important;
  height: 34px !important;
  box-shadow: 0 0 9px #00000075 !important;
  img {
    width: 20px !important;
    height: 20px !important;
    margin: 6px 7px !important;
  }
}
.white-overlay {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 225px;
    background: -o-linear-gradient(270.62deg, #ffffff 22.64%, #ffffff00 93.65%);
    background: linear-gradient(179.38deg, #ffffff 22.64%, #ffffff00 93.65%);
  }
}
.new-white-overlay {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0%;
    width: 50%;
    height: 110%;
    background: linear-gradient(90deg, #ffffffd9 14.6%, #ffffff00 102.07%);
  }
}
.white-home-overlay {
  position: relative;
  &::after {
    content: none !important;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 225px;
    background: -o-linear-gradient(270.62deg, #ffffff 22.64%, #ffffff00 93.65%);
    background: linear-gradient(179.38deg, #ffffff 22.64%, #ffffff00 93.65%);
  }
}
.new-white-home-overlay {
  position: relative;
  &::after {
    content: none !important;
    position: absolute;
    left: 0;
    top: 0%;
    width: 50%;
    height: 110%;
    background: linear-gradient(90deg, #ffffffd9 14.6%, #ffffff00 102.07%);
  }
}
.new-white-overlay-new {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0%;
    width: 80%;
    height: 110%;
    background: linear-gradient(90deg, #ffffffd9 14.6%, #ffffff00 102.07%);
  }
}
.right-white-overlay {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0%;
    width: 50%;
    height: 110%;
    background: linear-gradient(267deg, #ffffffd9 14.6%, #ffffff00 102.07%);
  }
}
.customAlign {
  @include media-breakpoint-up(lg) {
    top: 50% !important;
    transform: translate(0, -50%);
    left: 88px !important;
  }
}
.customAlignRight.legend {
  @include media-breakpoint-up(lg) {
    top: 50% !important;
    transform: translate(0, -50%);
    right: 0 !important;
    flex-direction: column;
    width: max-content !important;
    left: auto !important;
  }
}
.modal-backdrop {
  background-color: #3d393559 !important;
}
.footer_menu_links_wrap .start_order_btn_wrap,
.footer_menu_links_wrap .sign_in_btn_wrap,
.footer_menu_links_wrap .user-profile-details {
  display: none !important;
}
.theme_modal_close_button {
  width: 25px;
  height: 25px;
  display: inline-block;
  background-color: transparent;
  background: url("#{$REACT_APP_CLOUD_FRONT}/images/icons/x-icon.svg") no-repeat;
  background-size: contain;
  opacity: 1;
  outline: none !important;
  box-shadow: none !important;
  padding: 0;
  font-size: 0;
  &.pos-absolute {
    position: absolute;
    top: 2px;
    right: 5px;
  }
}
.download_app_modal {
  .modal-content {
    border: none;
    border-radius: 0;
    background: url("#{$REACT_APP_CLOUD_FRONT}/images/Header/download_app-bg.png")
      no-repeat #fff;
    background-position: 6% 93%;
    padding: 18px 24px 25px;
  }
}
.newsletter_modal {
  .modal-content {
    border: none;
    border-radius: 0;
    @include media-breakpoint-up(lg) {
      padding: 40px 60px;
    }
  }
  @include media-breakpoint-up(lg) {
    .modal-dialog {
      max-width: 700px;
    }
  }
}
.theme_breadcrumbs_style {
  margin: 40px 0 35px;
  @include media-breakpoint-down(lg) {
    margin: 35px 0 25px;
    padding: 0 24px;
  }
  .breadcrumb {
    margin-bottom: 0;
    background: transparent;
    padding: 0;
    border-radius: 0;
    .breadcrumb-item {
      @include font-sty(
        font(s16),
        #767676,
        font_fam(rale),
        $fs_weight: 500,
        $fs_lh: normal,
        $sm_lh: 19.9px,
        $sm_fs: font(s16)
      );
      span {
        text-decoration: underline;
      }
    }
  }
}
// New Form Design
.new_form_design {
  text-align: left;
  label {
    @include form_label;
    margin-bottom: 5px;
  }
  .form-group {
    margin-bottom: 9px;
  }
  &.gap-40 {
    .form-group {
      margin-bottom: 40px;
      @include media-breakpoint-down(md) {
        margin-bottom: 19px;
      }
    }
    .theme_check_boxes {
      margin-bottom: 60px;
      @include media-breakpoint-down(md) {
        margin-bottom: 0;
      }
    }
  }
  .form-control {
    @include new_form_control;
    &.input_error {
      color: Sec_clr(dark-red) !important;
      border: 1px solid Sec_clr(dark-red);
    }
  }
  input[type="password"] {
    font-family: font_fam(rale) !important;
    font-weight: 600;
  }
  .input_select {
    background: url("#{$REACT_APP_CLOUD_FRONT}/images/icons/Arrow-down-angle.svg")
      no-repeat #fff;
    background-size: font(s14);
    background-position: 97% 56%;
    -webkit-appearance: none;
    &::-ms-expand {
      display: none;
    }
  }
  input[readonly] {
    background-color: #f3f3f3;
  }
  input[type="date"] {
    line-height: 30px;
    text-align: left;
    ::-webkit-input-placeholder {
      color: transparent !important;
      opacity: 0 !important;
    }
    ::-moz-placeholder {
      color: transparent !important;
      opacity: 0 !important;
    }
    :-ms-input-placeholder {
      color: transparent !important;
      opacity: 0 !important;
    }
    :-moz-placeholder {
      color: transparent !important;
      opacity: 0 !important;
    }
    &::-webkit-datetime-edit-year-field:not([aria-valuenow]),
    &::-webkit-datetime-edit-month-field:not([aria-valuenow]),
    &::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
      color: transparent !important;
    }
    &.no-style {
      background: transparent;
    }
    &::-webkit-calendar-picker-indicator {
      background: none !important;
      bottom: 0;
      cursor: pointer;
      left: 90%;
      position: absolute;
      right: 0;
      top: 10px;
      height: 50%;
      width: 50%;
      margin-left: 0;
    }
    &::-webkit-date-and-time-value {
      text-align: left;
    }
    @include media-breakpoint-down(md) {
      line-height: 22px;
      display: flex !important;
    }
  }
  .error-msg-wrap {
    bottom: -15px;
  }
  .react-datepicker__input-container {
    position: relative;
    &:after {
      content: "";
      width: 12px;
      height: 8px;
      background: url("./images/arrow-down.svg") no-repeat;
      background-size: contain;
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      cursor: pointer;
    }
    input {
      @include new_form_control;
      width: 100%;
      padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    }
  }
  .react-datepicker__triangle {
    left: 50% !important;
    transform: translate(50%, 50%) !important;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: Pri_clr(dark-blue);
  }
  .arrow-right {
    background: url("#{$REACT_APP_CLOUD_FRONT}/images/icons/arrow-right.svg")
      no-repeat;
    background-size: 9px;
    background-position: center;
    width: 14px;
    height: 100%;
    &.position-absolute {
      top: 50%;
      right: 7px;
      transform: translateY(-50%);
    }
  }
  ::-webkit-input-placeholder {
    color: #afafaf !important;
  }
  ::-moz-placeholder {
    color: #afafaf !important;
  }
  :-ms-input-placeholder {
    color: #afafaf !important;
  }
  :-moz-placeholder {
    color: #afafaf !important;
  }
}
.custom_hr_sty {
  border-top: 1px solid Pri-clr(lt-black);
}
.hr-lt-grey {
  border-top: 1px solid #21212114;
}
.hide-shadow {
  &::after {
    content: none !important;
  }
}
.scrollbar-track {
  opacity: 0 !important;
}
.app_user_links {
  a {
    @include font_sty(
      font(s18),
      Pri_clr(lt-black) !important,
      font-fam(Vcf),
      $fs_lh: 21.6px
    );
    text-decoration: none;
    img {
      margin-bottom: -1px;
    }
    .link-text {
      @include font_sty(font(s11), $fs_lh: 21.13px);
      margin-bottom: 2px;
      line-height: normal;
      margin-left: 2px;
    }
  }
}
@media (min-width: 768px) {
  body .customTitleCareer {
    font-size: font(s27) !important;
  }
  body .customMargin {
    max-width: 900px !important;
  }
  .section_title_style {
    font-size: font(s48) !important;
    line-height: 48px !important;
  }
  .section_text_style {
    font-size: font(s24) !important;
    line-height: 26px !important;
  }
}
.customMobileFont {
  font-size: font(s18) !important;
}
@media (max-width: 768px) {
  .customMobileFont {
    font-size: font(s16) !important;
  }
}
.Toastify__toast-container.Toastify__toast-container--top-center {
  z-index: 999999;
  // @media (max-width: 480px) {
  //   padding: 10px !important;
  // }
  min-width: 320px;
  width: auto;
  @include media-breakpoint-down(md) {
    top: auto;
    bottom: 15px;
    width: 100vw;
  }
}
a {
  &:hover {
    cursor: pointer;
  }
}
.home_page_shapes {
  position: relative;
  @include media-breakpoint-only(md) {
    transform: translateY(-70px);
  }
  img {
    position: absolute;
    top: -260px;
    max-width: 530px;
    @include media-breakpoint-down(lg) {
      top: -170px;
      max-width: 330px;
    }
    @include media-breakpoint-down(lg) {
      max-width: 270px;
      position: unset;
    }
  }
  &.left {
    img {
      left: 0;
    }
  }
  &.right {
    img {
      right: 0;
    }
  }
}
.card_title_wrap {
  border-bottom: 1px solid #aa182c;
  padding: 16px 0 8px;
}
.filter_checkbox {
  input {
    &:checked {
      & + label {
        .box_wrap {
          background-color: transparent !important;
          border: 1px solid #3d3935 !important;
          &::after {
            border: solid #3d3935 !important;
            border-width: 0 2px 2px 0 !important;
          }
        }
      }
    }
  }
}
.theme_check_boxes {
  input {
    display: none;
    &:checked {
      & + label {
        .box_wrap {
          background-color: Pri_clr(dark-blue);
          border: none;
          border-radius: 2px;
          &::after {
            content: "";
            display: block;
            position: absolute;
            top: 3px;
            left: 7px;
            width: 5px;
            height: 10px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
          }
        }
      }
    }
    &:not(:checked) {
      & + label {
        .box_wrap {
          &::after {
            content: none;
          }
        }
      }
    }
  }
  label {
    font-weight: 500;
    letter-spacing: normal;
    font-family: font_fam(rale);
    font-size: font(s14);
    text-transform: none;
    pointer-events: none;
    .box_wrap {
      position: relative;
      width: 18px;
      height: 18px;
      border: 1px solid #3d3935;
      min-width: 18px;
      display: inline-block;
      border-radius: 4px;
      margin-right: 10px;
      cursor: pointer;
      pointer-events: all;
    }
  }
  &.rounded {
    input {
      &:checked {
        & + label {
          .box_wrap {
            background-color: transparent;
            &::after {
              border: none;
              width: 9px;
              height: 9px;
              border-radius: 20px;
              background-color: Pri_clr(dark-blue);
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
      &:not(:checked) {
        & + label {
          .box_wrap {
            &::after {
              content: none;
            }
          }
        }
      }
    }
    label {
      .box_wrap {
        border-radius: 30px !important;
      }
    }
    &.black {
      input {
        &:checked {
          & + label {
            .box_wrap {
              &::after {
                background-color: Pri_clr(lt-black);
              }
            }
          }
        }
      }
    }
  }
  &.Switch_btn {
    .box_wrap {
      width: 51px;
      height: 31px;
      background-color: #78788029;
      border: none;
      position: relative;
      &::after {
        content: "" !important;
        position: absolute;
        display: inline-block;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15),
          0px 3px 1px rgba(0, 0, 0, 0.06);
        top: 2px;
        left: 2px;
        transition: all 0.3s ease-in-out;
      }
    }
    .info_label {
      font-family: font-fam(Vcf);
      font-size: font(s14);
      color: Pri_clr(dark-blue);
      img {
        filter: invert(47%) sepia(46%) saturate(402%) hue-rotate(153deg)
          brightness(94%) contrast(93%);
        opacity: 0.9;
      }
    }
    input {
      &:checked {
        & + label {
          .box_wrap {
            background-color: #34c759;
            position: relative;
            &::after {
              @extend .box_wrap::after;
              left: 100%;
              transform: translate(calc(-100% - 2px), 0);
            }
          }
          .info_label {
            color: Pri_clr(dark-blue);
            img {
              filter: invert(47%) sepia(46%) saturate(402%) hue-rotate(153deg)
                brightness(94%) contrast(93%);
              opacity: 1;
            }
          }
        }
      }
    }
  }
  &.filter-wrap {
    .box_wrap {
      border-radius: 2px;
    }
    input:checked {
      + label {
        .box_wrap {
          &::after {
            top: 2px;
          }
        }
      }
    }
  }
}

.profile-wrapper {
  .nav {
    height: 100%;
    position: fixed;
    top: auto;
    padding-left: 25px;
    @include media-breakpoint-down(md) {
      width: 100%;
      border: none;
      position: relative;
      margin-bottom: 24px;
      top: 10px;
      padding-left: 0;
    }
    .dropdown {
      width: 100%;
      cursor: pointer;
      position: relative;
      z-index: 20;
      &:after {
        content: "";
        background: url("#{$REACT_APP_CLOUD_FRONT}/images/icons/Arrow-down-angle.svg")
          no-repeat #fff;
        background-size: 14px;
        position: absolute;
        right: 0px;
        top: 7.5px;
        display: block;
        width: 16px;
        height: 16px;
        z-index: -1;
      }
      &.active + .dropdownOption {
        display: block;
      }
    }
    .dropdownOption {
      padding: 10px 0 0 0;
      position: absolute;
      left: 0;
      top: 42px;
      right: 0;
      margin: 0;
      cursor: pointer;
      display: none;
      background: #fff;
      border: 1px solid #3d3935;
      border-radius: 0 0 5px 5px;
      z-index: 5;
      overflow: hidden;
      li {
        padding: 10px 15px;
        color: #fff;
        list-style: none;
        &:hover:not(.current) {
          color: grey;
        }
      }
    }
    .dropholder {
      border: 1px solid #3d3935;
      border-radius: 5px;
      height: 45px;
      padding: 13px 17px;
    }
    .nav-link {
      @include font_sty(
        font(s30),
        Pri_clr(lt-black),
        font-fam(Vcf),
        $fs-sty: uppercase,
        $fs_space: 0.5px,
        $sm_fs: font(s13),
        $sm_ff: font_fam(rale),
        $sm_sty: unset
      );
      font-size: 1.5857142857142858vw !important;
      text-align: left;
      color: #afafaf;
      line-height: 110%;
      @include media-breakpoint-down(md) {
        width: 100%;
        padding: 0;
        line-height: normal;
        white-space: nowrap;
        padding: 7px 15px;
        border-radius: 22px;
        color: #3d3935;
        margin-bottom: 10px;
        box-shadow: 1px 1px 5px #c0c0c0de;
        font-size: 14px !important;
        &:hover,
        &:focus,
        &:active,
        &.active {
          background-color: Pri_clr(dark-blue) !important;
          color: #ffffff !important;
          box-shadow: none;
        }
      }
      &:hover {
        background-color: transparent;
        color: Pri_clr(dark-blue);
        @include media-breakpoint-down(md) {
          text-decoration: unset;
          color: Pri_clr(lt-black);
        }
      }
      &:focus,
      &:active,
      &.active {
        background-color: transparent;
      }
    }
    button {
      @extend .nav-link;
      text-decoration: underline;
      @include media-breakpoint-down(md) {
        text-decoration: unset;
      }
    }
    .nav-item {
      @include media-breakpoint-down(md) {
        &:nth-child(4) {
          margin-bottom: 0;
        }
        &:nth-last-child(1) {
          border-bottom: none;
        }
        &.current {
          .nav-link {
            color: Pri_clr(dark-blue);
          }
        }
      }
    }
  }
  .is-active {
    color: Pri_clr(dark-blue) !important;
  }
}
.recent-order-wrap {
  .btn-small {
    padding: 6px 22px !important;
    @include media-breakpoint-down(md) {
      width: 100% !important;
    }
  }
}
.shadow-box {
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px rgba(61, 57, 53, 0.1);
  border-radius: 3px;
  position: relative;
  &.no-border {
    border: none;
  }
  &.rad-8 {
    border-radius: 8px;
  }
}
.items-inner-wrap {
  .shadow-box {
    @extend .shadow-box;
    &.item-wrap {
      padding: 15px 3.75vw;
      max-width: calc(16.66% - 14px);
      width: calc(16.66% - 14px);
      min-width: 200px;
      border: 1px solid #cccccc;
      box-shadow: 0px 0px 8.66509px rgba(61, 57, 53, 0.2);
      border-radius: 2.3px;
      text-align: center;
      @include media-breakpoint-down(xl) {
        padding: 15px 2.75vw;
      }
      @include media-breakpoint-down(lg) {
        max-width: calc(25% - 12px);
        width: calc(25% - 12px);
      }
      @include media-breakpoint-down(md) {
        padding: 15px 2vw;
        min-width: 105px;
        max-width: calc(33.33% - 13px);
        width: calc(33.33% - 13px);
      }
      @include media-breakpoint-down(sm) {
        max-width: calc(33.33% - 3px);
        width: calc(33.33% - 3px);
      }
    }
  }
}
.favourite-icon-wrap {
  position: absolute;
  top: 29px;
  right: 25px;
  outline: none !important;
  padding: 0;
  box-shadow: none !important;
  svg {
    width: 28px;
    height: 25px;
    path {
      fill: transparent;
      stroke: Pri_clr(lt-black);
      stroke-width: 2px;
      transition: all 0.3s ease-in-out;
    }
  }
  @include media-breakpoint-down(md) {
    top: 13px;
    right: 12px;
    svg {
      width: 23px;
      height: 20px;
    }
  }
}
.favorite-icon-blue {
  svg {
    path {
      fill: transparent;
      stroke: Pri_clr(dark-blue) !important;
    }
  }
}
.btn-custom {
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  border: none !important;
  font-weight: 500;
  font-family: font-fam(rale);
  pointer-events: all;
}
.clr_black {
  border-top-color: #000;
}
.item-selector {
  position: relative;
  .img_box {
    .avatar {
      width: 97px;
      min-width: 97px;
      height: 97px;
      background-color: Sec_clr(text-grey);
      border-radius: 50%;
      padding: 14px;
      @include media-breakpoint-down(md) {
        width: 68px;
        min-width: 68px;
        height: 68px;
      }
    }
  }
  .rounded-box {
    background-color: Sec_clr(text-grey);
  }
  input {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    &:checked + label {
      border: 3px solid Pri_clr(dark-blue) !important;
      .img_box {
        .avatar {
          background-color: Pri_clr(dark-blue);
        }
      }
      .rounded-box {
        background-color: Pri_clr(dark-blue);
      }
      button {
        background-color: Pri_clr(dark-blue);
        color: #fff;
        border: 0;
      }
    }
  }
  .optional-selector {
    cursor: pointer;
  }
  .desktop-selected-text {
    display: block;
    margin-bottom: -30px;
    margin-top: 10px;
  }
}
.back-button-link {
  color: Pri_clr(lt-black);
  &.btn-absolute {
    position: absolute;
    left: 0;
    transform: translateY(-50%);
    top: 50%;
  }
  span {
    margin-left: 10px;
    font-weight: 800;
    font-size: font(s16);
    line-height: 19px;
    text-transform: uppercase;
  }
  &:hover {
    text-decoration: none;
    color: Sec_clr(text-grey);
  }
}
.no-gap {
  gap: unset !important;
}
.modal-close-button {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: transparent;
  background: url("#{$REACT_APP_CLOUD_FRONT}/images/icons/x-icon.svg") no-repeat;
  background-size: contain;
  opacity: 1;
  outline: none !important;
  box-shadow: none !important;
  position: absolute;
  top: 12px;
  right: 11px;
  z-index: 999;
  &.static {
    position: static;
  }
  &.red-hover {
    &:hover,
    &:focus,
    &:active {
      opacity: 1;
      filter: invert(16%) sepia(96%) saturate(2481%) hue-rotate(337deg)
        brightness(54%) contrast(100%);
    }
  }
  &.blue-hover {
    &:hover,
    &:focus,
    &:active {
      opacity: 1;
      filter: invert(28%) sepia(6%) saturate(2886%) hue-rotate(154deg)
        brightness(88%) contrast(88%);
    }
  }
  @media (min-width: 768px) {
    top: 15px;
    right: 15px;
  }
  &.blue-icon {
    filter: invert(38%) sepia(74%) saturate(278%) hue-rotate(153deg)
      brightness(90%) contrast(88%);
    font-weight: 900;
    width: 23px;
    height: 22px;
  }
  &.sm-blue-icon {
    @include media-breakpoint-down(md) {
      filter: invert(38%) sepia(74%) saturate(278%) hue-rotate(153deg)
        brightness(90%) contrast(88%);
      font-weight: 900;
      width: 23px;
      height: 22px;
    }
  }
}
.my-card-modal {
  .modal-dialog {
    margin-top: 0 !important;
  }
  .modal-content,
  .modal-header {
    border: unset !important;
    border-radius: 0;
  }
  .modal-header {
    padding: 24px 30px;
  }
  .modal-body {
    padding: 0;
  }
}
.item-select-btn {
  font-size: font(s11);
  font-family: font_fam(rale);
  border-color: Sec_clr(text-grey);
  min-width: 100%;
  width: 100%;
  max-width: 110px;
  box-shadow: none;
  padding: 7px 7px;
  line-height: normal;
  text-transform: none;
  border-radius: 30px;
  outline: none !important;
  box-shadow: none !important;
  &:hover {
    background-color: Pri_clr(dark-blue) !important;
    border-color: Pri_clr(dark-blue) !important;
    color: #fff !important;
    @include media-breakpoint-down(md) {
      background-color: initial !important;
      color: initial !important;
      border-color: initial !important;
    }
  }
}
.ingredients_modal {
  @include media-breakpoint-down(lg) {
    max-width: calc(100% - 64px);
    .modal-dialog {
      max-width: 100%;
    }
  }
  &.modal {
    @include media-breakpoint-down(md) {
      max-width: calc(100% - 12px);
      margin-left: 6px;
      margin-right: 0;
    }
  }
  .modal-content {
    border: none;
    border-radius: 5px;
    padding: 0 50px;
    @include media-breakpoint-down(md) {
      padding: 0 0;
      .container-fluid,
      .modal-header {
        padding-left: 11px;
        padding-right: 11px;
      }
    }
    .menu_tabs_wrap {
      max-width: 100%;
    }
    .tab-content {
      padding-bottom: 0;
    }
  }
  &.dressing-modal {
    .items-wrap {
      @include media-breakpoint-down(md) {
        padding-left: 11px !important;
        padding-right: 11px !important;
      }
    }
  }
}
.tabs-padd-0 {
  .tab-content {
    padding-bottom: 0;
  }
}
.modal.right {
  .modal-dialog {
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    @media (max-width: 767.98px) {
      margin-top: 73px;
    }
    @media (max-width: 575.98px) {
      margin-left: 0;
    }
  }
}
.theme-modal-style {
  .modal-content {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(61, 57, 53, 0.2);
    padding: 0 50px;
    @include media-breakpoint-down(md) {
      padding: 0 20px;
    }
    .modal-header {
      border: none;
    }
  }
  &.xs-modal {
    .modal-dialog {
      @include media-breakpoint-down(sm) {
        margin-left: 0;
        margin-right: 0;
      }
      .modal-content {
        width: 414px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.profile-info-wrap {
  padding-bottom: 28px;
  .accordion {
    .card {
      border-width: 1px 0 1px 0;
      border-color: #ebebeb;
      padding: 16px 0;
      .card-header {
        padding: 0;
        border: none;
        background-color: transparent;
        .btn-link {
          @include font_sty(
            font(s22),
            Pri_clr(lt-black),
            font_fam(Vcf),
            $fs_lh: normal,
            $fs_space: normal,
            $fs-sty: uppercase
          );
          padding: 0;
          text-decoration: none !important;
          position: relative;
          z-index: 0;
          width: 100%;
          text-align: left;
          @extend %Removed_extra_sty;
          &:after {
            content: "";
            background: url("#{$REACT_APP_CLOUD_FRONT}/images/icons/Arrow-down-angle.svg")
              no-repeat #fff;
            background-size: 15px;
            position: absolute;
            right: 0px;
            top: 9.5px;
            display: block;
            width: 16px;
            height: 8px;
            z-index: -1;
            transition: all 0.3s ease-in-out;
          }
          &.active {
            &:after {
              transform: rotate(180deg);
            }
          }
        }
      }
      .card-body {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 5px;
        ul {
          padding-left: 0;
        }
      }
    }
  }
  .btn-custom.sign-out {
    @include font_sty(
      font(s22),
      Pri_clr(lt-black),
      font_fam(Vcf),
      $fs_lh: normal,
      $fs_space: normal,
      $fs-sty: uppercase
    );
    padding: 0;
    text-decoration: none !important;
    @extend %Removed_extra_sty;
  }
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.Menu-items-wrapper {
  .nav-item {
    &.selected {
      opacity: 0.4;
      cursor: not-allowed;
      a {
        cursor: not-allowed;
      }
    }
  }
}
.cursor-pointer {
  cursor: pointer !important;
}
.error_symbol {
  width: 27px;
  min-width: 27px;
  height: 27px;
  background-color: Pri_clr(dark-blue);
  border-radius: 57px;
  text-align: center;
  display: inline-block;
  color: #fff;
  @include media-breakpoint-down(md) {
    width: 23px;
    min-width: 23px;
    height: 23px;
    padding-top: 2px;
  }
}
.opacity_0 {
  opacity: 0;
}
.sticky-links-wrap {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 999;
  .sticky-links {
    @include font_sty(
      font(16),
      Pri_clr(dark-blue),
      font_fam(Vcf),
      $fs_lh: normal,
      $fs_space: normal
    );
    background-color: white;
    border-radius: 25px;
    border: 1px solid #f6f6f6;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    text-decoration: none !important;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    img {
      margin-right: 8px;
    }
  }
  &.modal_links {
    z-index: 99999;
  }
}
.recent_order_modal {
  top: calc(var(--get-nav-height));
  min-height: calc(100vh - var(--get-nav-height));
  max-height: calc(100vh - var(--get-nav-height));
  .modal-dialog {
    align-items: flex-start;
    max-width: 100%;
    margin: 0;
    min-height: 100%;
    background-color: #fff;
    .modal-content {
      width: 100%;
      box-shadow: unset;
      border: none;
      border-radius: 0;
      min-height: 100%;
    }
  }
}

.custom_backdrop {
  top: 153px;
  height: calc(100vh - 153px);
  background-color: transparent !important;
  @include media-breakpoint-down(md) {
    max-height: calc(100vh - 85px);
    top: 85px;
  }
}
.rename_order_modal_backdrop,
.email_receipt_modal_backdrop,
.rewards_details_moda_backdrop {
  z-index: 10000;
}
.rename_order_modal,
.email_receipt_modal,
.rewards_details_modal {
  z-index: 10001;
}
.lg-fluid {
  @include media-breakpoint-up(lg) {
    width: 100% !important;
    max-width: 100% !important;
  }
}
.rewards-tabs-content {
  .rewards-nav-header {
    .rewards_left_shape,
    .rewards_right_shape {
      img {
        object-fit: cover;
      }
      @include media-breakpoint-down(md) {
        display: none !important;
      }
    }
    .rewards-tabs-nav {
      .nav {
        .nav-item {
          padding-right: 93px;
          @include media-breakpoint-down(md) {
            padding-right: 40px;
          }
          &:last-child {
            padding-right: 0;
          }
          .nav-link {
            @include font_sty(
              font(s18),
              Pri_clr(lt-black),
              font_fam(Vcf),
              $sm_fs: font(s16),
              $fs-sty: uppercase
            );
            border-radius: 0;
            padding: 0;
            &:hover,
            &:focus,
            &:active,
            &.active {
              background-color: transparent;
              border-bottom: 6px solid Pri_clr(dark-blue);
            }
          }
          &:nth-child(2) {
            .nav-link {
              &:hover,
              &:focus,
              &:active,
              &.active {
                border-bottom-color: #759169;
              }
            }
          }
          &:nth-child(3) {
            .nav-link {
              &:hover,
              &:focus,
              &:active,
              &.active {
                border-bottom-color: #e37736;
              }
            }
          }
        }
      }
    }
  }
  .tab-content {
    .tab-pane {
      padding: 70px 70px;
      min-height: calc(100vh - 427px);
      &:first-child {
        background: linear-gradient(360deg, #0956a880 0%, #c7dbe480 100%);
      }
      &:nth-child(2) {
        background: linear-gradient(180deg, #c2cebc4f 0%, #c2cebc 100%);
      }
      &:nth-child(3) {
        background: linear-gradient(0deg, #f7b984 0%, #f7b98426 101.51%);
      }
      @include media-breakpoint-down(md) {
        padding: 40px 24px 50px;
        min-height: calc(100vh - 247px);
      }
    }
  }
  &.header-collapse {
    padding-top: 136px;
    .rewards_left_shape,
    .rewards_right_shape {
      display: none !important;
    }
    .rewards-tabs-nav {
      max-width: calc(100% - 15px);
      width: calc(100% - 15px);
      background: #f3f3f3;
      box-shadow: 0px 0px 8.66509px #3d393533;
      position: fixed;
      top: var(--get-nav-height);
      z-index: 99;
      border: none;
      h6,
      h1,
      h2 {
        display: none !important;
      }
      @include media-breakpoint-down(md) {
        max-width: 100%;
        width: 100%;
      }
    }
    .collapse-filter {
      width: calc(100% - 15px);
      @include media-breakpoint-down(md) {
        max-width: 100%;
        width: 100%;
      }
    }
  }
  .rewards-menu-list {
    li {
      margin-right: 14px;
      &:last-child {
        margin-right: 0;
      }
      .menu_cat_link {
        @include font_sty(font(s18), Pri_clr(lt-black), $fs_weight: 500);
        border: 1px solid Sec_clr(text-grey);
        border-radius: 5px;
        padding: 7px 15px;
        display: inline-block;
        margin-bottom: 16px;
        &:hover,
        &:focus,
        &:active,
        &.active {
          color: Sec_clr(sec-white);
          background-color: Pri_clr(lt-black);
        }
        @include media-breakpoint-down(md) {
          margin-bottom: 14px;
        }
      }
    }
  }
  .form-group {
    &.theme_check_boxes {
      @include media-breakpoint-down(md) {
        margin-bottom: 12px;
      }
    }
  }
}
.rewards_details_modal {
  bottom: -100vh;
  transition: all 0.4s ease-in-out;
  &.show {
    bottom: 0;
    opacity: 1;
    top: auto;
  }
  @include media-breakpoint-down(md) {
    .modal-dialog {
      align-items: flex-end;
      margin: 0;
      height: 100%;
      max-width: 100%;
    }
    .modal-content {
      border: none;
      box-shadow: unset;
      border-radius: 0;
    }
  }
}
.rewards_items_modal,
.favorite_items_modal,
.recent_order_modal {
  z-index: 99;
  .modal-content {
    max-height: 100%;
  }
}
.rewards_items_modal_backdrop,
.favorite_items_modal_backdrop,
.recent_order_modal_backdrop {
  z-index: 9;
}
.counter-animate {
  span {
    display: inline-block;
    animation: beat 0.25s infinite alternate;
    transform-origin: center;
  }
  @keyframes beat {
    to {
      transform: scale(1.2);
    }
  }
}
.rewards_items_modal {
  .modal-dialog {
    height: 100%;
    max-height: 100%;
  }
}
// .modal-open {
//   #header_container {
//     width: calc(100% - 16px);
//   }
// }
.require-text-boxes {
  position: relative;
  span {
    width: 29px;
    min-width: 29px;
    height: 29px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    background-color: Pri_clr(dark-blue);
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.btn-checkboxes {
  position: relative;
  input {
    position: absolute;
    width: 0;
    height: 0;
    &:checked {
      & + label {
        background-color: Pri_clr(dark-blue);
        color: Sec_clr(sec-white);
      }
    }
  }
  label {
    border: 1px solid Pri_clr(dark-blue);
    box-shadow: 0px 0px 5px #3d39351a;
    border-radius: 56px;
    padding: 10px 20px;
    line-height: normal;
    cursor: pointer;
    min-width: max-content;
    @include font_sty(font(s16), Pri_clr(dark-blue), font_fam(Vcf));
    &:hover,
    &:focus {
      background-color: Pri_clr(dark-blue);
      color: Sec_clr(sec-white);
    }
    @include media-breakpoint-down(md) {
      padding: 10px 12px;
    }
  }
}
.free-tag {
  color: Pri_clr(dark-blue);
  text-transform: uppercase;
  font-family: font_fam(Vcf);
  padding-left: 25px;
}
.rewards_redeem_modal {
  .modal-dialog {
    @include media-breakpoint-down(sm) {
      margin: 0;
    }
    .modal-content {
      background: #fff;
      box-shadow: unset;
      border: none;
      .modal-body {
        padding: 41px 95px 54px;
        background: linear-gradient(180deg, #c2cebc4f 0%, #c2cebc 100%);
        @include media-breakpoint-down(sm) {
          padding: 36px;
        }
      }
    }
  }
}
.rewards_redeem_modal_backdrop,
.on_modal_backdrop {
  z-index: 9999;
}
.theme-modal-style {
  &.nor-padding {
    .modal-content {
      padding: 15px 30px;
      position: relative;
      @include media-breakpoint-down(md) {
        padding: 10px;
      }
    }
  }
  &.border-radius-15 {
    .modal-content {
      border-radius: 15px;
    }
    &.border-radius-sm-8 {
      .modal-content {
        @include media-breakpoint-down(md) {
          border-radius: 15px;
        }
      }
    }
    &.border-radius-sm-12 {
      .modal-content {
        @include media-breakpoint-down(md) {
          border-radius: 12px;
        }
      }
    }
    &.upperside-radius-sm-only {
      .modal-content {
        @include media-breakpoint-down(md) {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
    &.no-radius-sm-only {
      .modal-content {
        @include media-breakpoint-down(md) {
          border-radius: 0 !important;
        }
      }
    }
    &.max_width_567 {
      .modal-content {
         max-width: 567px;
         margin: auto;
      }
    }
  }
  &.border-radius-8 {
    .modal-content {
      border-radius: 8px;
    }
    &.border-radius-sm-15 {
      .modal-content {
        @include media-breakpoint-down(md) {
          border-radius: 15px;
        }
      }
    }
    &.border-radius-sm-12 {
      .modal-content {
        @include media-breakpoint-down(md) {
          border-radius: 12px;
        }
      }
    }
    &.upperside-radius-sm-only {
      .modal-content {
        @include media-breakpoint-down(md) {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }
  &.fav-location-modal {
    bottom: -100vh;
    transition: all 0.4s ease-in-out;
    &.show {
      bottom: 0;
      opacity: 1;
      top: auto;
      display: flex;
    }
    .modal-body,
    .modal-footer {
      width: 320px;
      margin: 0 auto;
    }
    @include media-breakpoint-down(sm) {
      .modal-dialog {
        align-items: flex-end;
        margin: 0;
        height: 100%;
      }
      .modal-content {
        border: none;
        box-shadow: unset;
        border-radius: 0;
      }
    }
  }
  .modal-content {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(61, 57, 53, 0.2);
    .modal-header {
      border: none;
    }
  }
  &.xs-modal {
    .modal-content {
      width: 375px;
    }
  }
  .modal-footer {
    border: none;
    border-radius: 0;
  }
  .modal-rounded-img {
    width: 112px;
  }
  &.no-border {
    .modal-content {
      border: none;
    }
  }
  
}
.modal-close-button {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: transparent;
  background: url("#{$REACT_APP_CLOUD_FRONT}/images/icons/x-icon.svg") no-repeat;
  background-size: contain;
  opacity: 1;
  outline: none !important;
  box-shadow: none !important;
  position: absolute;
  top: 12px;
  right: 11px;
  z-index: 999;
  font-size: 0;
  &.static {
    position: static;
  }
  &.red-hover {
    &:hover,
    &:focus,
    &:focus-visible,
    &:active {
      opacity: 1;
      filter: invert(16%) sepia(96%) saturate(2481%) hue-rotate(337deg)
        brightness(54%) contrast(100%);
    }
  }
  @media (min-width: 768px) {
    top: 15px;
    right: 15px;
  }
}
.rewards_modal {
  .modal-dialog {
    @include media-breakpoint-up(md) {
      max-width: 563px;
    }
  }
  .modal-body {
    img {
      margin-bottom: 15px;
    }
    @include media-breakpoint-up(lg) {
      img {
        width: 144px;
        margin-bottom: 20px;
      }
      .modal_des {
        width: 75%;
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer !important;
}
.index-h1 {
  position: absolute;
  opacity: 0;
  z-index: -1;
  top: 0;
  color: #00000000;
}
.unset-style {
  padding: 0;
  position: relative;
  border: none;
  border-radius: 0;
}
.invite-friend-modal {
  overflow: hidden !important;
  .modal-dialog {
    min-height: 100%;
    margin-top: 0;
    margin-bottom: 0;
    position: relative;
    animation: animatebottom 0.4s;
    @include media-breakpoint-down(sm) {
      margin: 0;
      align-items: flex-end;
    }
    .modal-content {
      max-width: 380px;
      margin-left: auto;
      margin-right: auto;
      .modal-body {
        padding: 30px 24px;
      }
    }
  }
  @keyframes animatebottom {
    from {
      bottom: -300px;
      opacity: 0;
    }

    to {
      bottom: 0;
      opacity: 1;
    }
  }
}
.address_info_wrap {
  border: 1px solid #3d3935;
  box-sizing: border-box;
  border-radius: 5px;
  min-height: 230px;
  max-height: 230px;
  position: relative;
  overflow: auto;
  .input-tag-items {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 12px 11px;
    position: absolute;
    cursor: text;
    align-content: flex-start;
  }
}
.input-tag-items {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  .tag-item {
    @include font_sty(font(s16), $fs-weight: 500);
    display: flex;
    align-items: center;
    padding: 8px 16px;
    width: auto;
    height: 39px;
    background-color: #f3f3f3;
    border-radius: 50px;
    position: relative;
    padding-right: 40px;
    padding-left: 16px;
    .modal-close-button {
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .form-control {
    @include font_sty(font(s16), $fs-weight: 500);
    border: none;
    padding: 0;
    @extend %Removed_extra_sty;
  }
}
.label-require {
  @include font_sty(
    font(s13),
    Sec_clr(text-grey),
    font_fam(rale),
    $fs-weight: 500,
    $fs-sty: none,
    $fs-space: normal
  );
}
.search-active {
  .sticky-links-wrap {
    z-index: -1;
  }
}

.field-icon {
  position: absolute;
  top: 50%;
  right: 0.75rem;
  transform: translateY(-50%);
}
.card-field {
  .form-group {
    margin: 0;
    label {
      display: none;
    }
    .custom-style {
      height: 35px;
      padding: 3px 4px;
      width: 50px;
      outline: none !important;
      box-shadow: none !important;
    }
  }
}
.select_size_box_dressing {
  .item_wrap {
    padding: 20px 10px;
    width: calc(33.33% - 10px);
    @media (max-width: 575.98px) {
      max-width: unset;
      width: calc(33.33% - 5px);
    }
  }
}
.black-border {
  border: 2px solid #3d3935;
}

.custom-success-toast {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  @include media-breakpoint-up(md) {
    color: font_clr(dark-grey) !important;
    padding: 0 5px;
  }
  @include media-breakpoint-down(md) {
    color: #fff !important;
  }
  &.Toastify__toast--success {
    background-color: white;
    border-radius: 8px;
    margin: 0 15px;
    @include media-breakpoint-up(md) {
      height: 120px;
    }
    @include media-breakpoint-down(md) {
      background-color: Pri_clr(dark-blue);
    }
  }
  color: Pri_clr(dark-blue);
  .Toastify__progress-bar {
    background-color: Pri_clr(dark-blue);
    @include media-breakpoint-up(md) {
      background-color: font_clr(dark-grey);
      visibility: hidden;
    }
  }
  .Toastify__close-button {
    color: Pri_clr(dark-blue);
  }
}

.custom-error-toast {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  padding: 0 5px;
  &.Toastify__toast--error {
    background-color: $white;
    margin: 0 15px;
    border-radius: 8px;
    @include media-breakpoint-up(md) {
      min-height: 120px;
    }
  }
  color: Sec_clr(dark-red);
  .Toastify__progress-bar {
    background-color: Sec_clr(dark-red);
    visibility: hidden;
  }
  .Toastify__close-button {
    color: Sec_clr(dark-red);
    @include media-breakpoint-down(md) {
      color: $white;
    }
  }
}
.categories_button {
  border: 1px solid #cccccc;
  box-shadow: 0px 0px 8.66509px #3d393533;
  border-radius: 2.3px;
  &.active {
    background-color: transparent !important;
    border-color: Sec_clr(dark-red) !important;
  }
}

// Fab styling
.fab-wrapper {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  &.available {
    bottom: 9rem;
  }
  &.notAvailable {
    bottom: 6rem;
  }
  .fab-checkbox {
    display: none;
    &:checked {
      ~ .fab .fab-dots {
        animation: none;
      }
      ~ .fab .fab-menu-btn {
        display: none;
      }
      ~ .fab .fab-close-btn {
        display: block;
        svg {
          @include media-breakpoint-down(md) {
            height: 22px;
          }
        }
      }
      ~ .fab-wheel {
        .fab-action-1 {
          transform: scale(1);
          bottom: 80px;
          transition: all 0.3s ease-in 0.2s;
        }
        .fab-action-1-should-release-position {
          transform: scale(1);
          bottom: 120px;
          transition: all 0.3s ease-in 0.2s;
        }
        .fab-action-2 {
          transform: scale(1);

          bottom: 310px;
          transition: all 0.3s ease-in 0.3s;
        }
        .fab-action-2-should-release-position {
          transform: scale(1);
          bottom: 75px;
          transition: all 0.3s ease-in 0.2s;
        }
        .fab-action-3 {
          transform: scale(1);
          bottom: 255px;
          transition: all 0.3s ease-in 0.4s;
        }
        .fab-action-3-should-release-position {
          transform: scale(1);
          bottom: 20px;
          transition: all 0.3s ease-in 0.2s;
        }
        .fab-action-4 {
          transform: scale(1);
          bottom: 195px;
          transition: all 0.3s ease-in 0.4s;
        }
        .fab-action-5-should-release-position {
          transform: scale(1);
          bottom: 30px;
          transition: all 0.3s ease-in 0.2s;
        }
        .fab-action-5 {
          transform: scale(1);

          bottom: 135px;
          transition: all 0.3s ease-in 0.4s;
        }
      }
      ~ .fab-overlay {
        opacity: 1;
      }
      ~ .fab-wheel {
        pointer-events: all;
      }
    }
  }
  .fab {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 64px;
    height: 64px;
    background: #ffffff;
    color: $white;
    background: linear-gradient(0deg, Pri-clr(dark-blue), Pri-clr(dark-blue)),
      linear-gradient(0deg, #ffffff, #ffffff);
    border-radius: 16px;
    z-index: 2;
    border: 1.5px solid #ffffff;
    box-shadow: 0px 1px 6px 2px #00000040;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in 0.4s;
    cursor: pointer;
    @include media-breakpoint-down(md) {
      width: 50px;
      height: 50px;
      border-radius: 10px;
    }
    .fab-close-btn {
      display: none;
    }
    svg {
      @include media-breakpoint-down(md) {
        width: 30px;
        height: 30px;
      }
    }
  }
  .fab-wheel {
    position: absolute;
    bottom: 0;
    right: 0;
    border: 1px solid transparent;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    pointer-events: none;
  }
  .fab-action {
    position: absolute;
    transform-origin: bottom right;
    transform: scale(0);
    right: 0;
    bottom: 0;
    &.fab-action-1 {
      transition: all 0.3s ease-out 0.4s;
    }
    &.fab-action-2 {
      transition: all 0.3s ease-out 0.3s;
    }
    &.fab-action-3 {
      transition: all 0.3s ease-out 0.2s;
    }
    &.fab-action-4 {
      transition: all 0.3s ease-out 0.2s;
    }
    &:hover,
    &:active,
    &:focus {
      transition: none;
    }
  }
  .fab-overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    bottom: 0;
    right: 0;
    z-index: 0;
    opacity: 0;
    background-color: #00000033;
    transition: all 1s ease-out 0.4s;
    pointer-events: none;
  }
}
button {
  &[title="Keyboard shortcuts"] {
    display: none !important;
  }
  &[title="Map Data"] {
    @extend [title="Keyboard shortcuts"];
    + span {
      display: none !important;
    }
  }
}
a {
  &[rel="noopener"][target="_blank"] {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  img {
    & [alt="Google"] {
      width: 75px;
      height: 32px;
    }
  }
}

.modal-sm-90w {
  width: 100%;
  max-width: 100%;
  @include media-breakpoint-up(lg) {
    width: 90%;
    max-width: 90%;
  }
  @include media-breakpoint-down(md) {
    margin: 0;
    align-items: flex-end;
    min-height: 100vh;
    .modal-content {
      box-shadow: unset;
      border: none;
    }
  }
}
.swiper {
  .swiper-wrapper {
    align-items: stretch;
    .swiper-slide {
      height: auto;
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    &:after {
      color: Pri-clr(lt-black);
      font-size: 25px;
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    &.swiper-button-disabled {
      opacity: 0;
    }
    .swiper-button-next {
      &::after {
        color: #3d3935;
      }
    }
  }
  .swiper-pagination {
    .swiper-pagination-bullet {
      background-color: #f0f0f0;
      height: 15px;
      width: 15px;
      opacity: 1;
      @include media-breakpoint-down(md) {
        height: 6px;
        width: 6px;
      }
      &.swiper-pagination-bullet-active {
        background-color: Pri-clr(dark-blue);
      }
    }
  }
  &.rewards-swiper {
    .swiper-button-next,
    .swiper-button-prev {
      position: absolute;
      top: initial;
      z-index: 11;
      bottom: 0;
      cursor: pointer;
      &::after {
        font-size: 18px;
        font-weight: 600;
      }
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
    .swiper-button-next {
      right: 95px;
    }
  }
}
.dressing-mobile-view,
.other-dressing-view,
.Complimentary_sides-view {
  @include media-breakpoint-down(md) {
    .row {
      margin-left: -4px;
      margin-right: -4px;
      .col-4 {
        padding-left: 4px;
        padding-right: 4px;
        margin-bottom: 8px;
      }
    }
  }
}
.other-dressing-view {
  &.try2_dressing_modal {
    padding: 0 80px;
    @include media-breakpoint-down(xl) {
      padding: 0 50px;
    }
    @include media-breakpoint-down(md) {
      padding: 0 0;
    }
  }
}
.back-arrow-btn {
  display: inline-flex;
  align-items: center;
  margin-bottom: 20px;
  line-height: 0;
  &::before {
    content: "";
    border: 7.5px solid transparent;
    border-radius: 3px;
    display: inline-block;
    border-right-color: Pri-clr(dark-blue);
    margin-right: 5px;
    border-left: 0;
  }
}
.try2-require-items-wrap {
  @include media-breakpoint-down(lg) {
    &.row {
      margin-left: -8px;
      margin-right: -8px;
      .col-6,
      .col-5,
      .col-sm-6 {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }
}
.theme-custom-accordions {
  .accordion-item {
    border-width: 0;
    border-bottom-width: 1px;
    border-radius: 0;
    .accordion-button,
    .accordion-button:not(.collapsed):not(.navbar-toggler.collapsed:focus) {
      @include font-sty(
        font(s30),
        Pri_clr(lt-black),
        font_fam(Cls),
        $sm_fs: font(s18),
        $fs_weight: 400
      );
      padding-top: 19px;
      padding-bottom: 19px;
      align-items: flex-start;
      &:after {
        background-image: url("./images/accordion-polygon.svg");
        background-size: contain;
        height: 10px;
        margin-left: 20px;
        margin-top: 17px;
        @include media-breakpoint-down(sm) {
          width: 16px;
          height: 10px;
          margin-left: 10px;
          margin-top: 8px;
        }
      }
      padding-left: 0;
      padding-right: 0;
      z-index: 0;
      &:focus,
      &:active,
      &:hover {
        background-color: transparent;
        outline: none;
        box-shadow: none;
      }
      &:not(.collapsed) {
        background-color: transparent;
        outline: none;
        box-shadow: none;
      }
    }
    .accordion-body {
      padding: 0;
      margin-bottom: 50px;
    }
  }
}
.icon-20 {
  width: 20px;
}
.border-line {
  background: #000000;
  border-radius: 100px;
  width: 134px;
  height: 5px;
  display: inline-block;
  margin: 0 auto;
}
.arrow-up-btn {
  img {
    width: 22px;
    margin: 0 auto;
    margin-bottom: 8px;
  }
}
.content-divider {
  @include media-breakpoint-down(md) {
    max-width: 75%;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 40px auto;
  z-index: 0;
  &::before {
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    background-color: Sec_clr(lt-grey);
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: -1;
  }
  span {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    color: Sec_clr(lt-grey);
    font-size: font(f-s14);
    font-family: font-fam(Vcf);
    text-transform: uppercase;
  }
}
.login-content-divider {
  &::before {
    top: 95%;
  }
}
.account-social-icons {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-bottom: 30px;
  li {
    margin-right: 48px;
    &:last-child {
      margin-right: 0;
    }
    button {
      width: 50px;
      height: 50px;
      border-radius: 8px;
      background: #ffffff;
      border: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 0px 4px #00000040, 0px 4px 4px #00000040;
      padding: 0;
      &:hover,
      &:focus,
      &:active {
        outline: none !important;
      }
    }
  }
}
.absolute-back-btn {
  display: none;
  @include media-breakpoint-up(md) {
    position: absolute;
    top: 7%;
    left: 9%;
    display: block;
  }
}
.shadow-divider {
  background: #ffffff;
  box-shadow: 0px 3px 6px #d0d0d040;
  opacity: 1;
  height: 10px !important;
  margin: 0 0 40px;
}
.edit-action-btn {
  @include media-breakpoint-down(md) {
    font-size: 0 !important;
    background: url("./images/pencil-edit.svg") no-repeat;
    width: 24px;
    height: 24px;
    order: 2;
  }
}
.edit-action-btn-for-all-screens {
  font-size: 0 !important;
  background: url("./images/pencil-edit.svg") no-repeat;
  width: 24px;
  height: 24px;
  order: 2;
}
.remove-action-btn {
  @include media-breakpoint-down(md) {
    font-size: 0 !important;
    background: url("./images/remove-icon.svg") no-repeat;
    width: 24px;
    height: 24px;
  }
}
.type-link-dropdown {
  .dropdown-toggle {
    color: Pri_clr(lt-black) !important;
    font-family: font-fam(Vcf);
    background: transparent !important;
    padding: 0;
    border: 0;
    border-left: 1px solid #e1e1e1 !important;
    border-right: 1px solid #e1e1e1 !important;
    border-radius: 0;
    text-decoration: none;
    padding: 0 12px;
    &:hover,
    &:focus,
    &:active {
      outline: none;
      border: none;
      background-color: transparent;
      box-shadow: none !important;
    }
    &::after {
      border-top-color: Pri_clr(dark-blue);
      border: none;
      background: url("./images/arrow-up.svg") no-repeat;
      width: 13px;
      height: 8px;
      margin-left: 8px;
      transform: rotate(180deg);
    }
    &[aria-expanded="true"] {
      &::after {
        transform: none;
      }
    }
    div {
      margin-left: 4px;
    }
  }
  .dropdown-menu {
    box-shadow: 0px -1px 20px #00000040;
    border-radius: 4px;
    width: 340px;
    padding: 20px 15px;
    border: 0;
    text-align: center;
    .dropdown-item {
      @include font_sty(
        font(s16),
        Pri_clr(dark-blue) !important,
        font-fam(Vcf),
        $fs_lh: 21.6px,
        $fs_space: 0.3px
      );
      text-align: center;
      border-bottom: 1px solid #e1e1e1;
      padding: 16px;
      .number {
        font-size: font(s36);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &.clr-lt-black {
        color: Pri_clr(lt-black) !important;
        text-transform: uppercase;
        .number {
          color: Pri_clr(dark-blue) !important;
        }
      }
      &:hover,
      &:focus,
      &:active {
        outline: none;
        border: none;
        border-bottom: 1px solid #e1e1e1;
        background-color: transparent;
        box-shadow: none !important;
      }
    }
    .cms-pages {
      .dropdown-item {
        font-size: font(s14);
        padding: 0;
        display: inline-block;
        text-align: unset;
        width: auto;
        border-bottom: none;
      }
    }
  }
  .btn-small {
    margin: 40px auto 24px;
  }
}
.error-state {
  input {
    border: 1px solid Sec-clr(dark-red) !important;
    color: Sec-clr(dark-red) !important;
  }
}
.valid-state {
  input {
    border: 1px solid Pri-clr(dark-blue) !important;
    color: Pri-clr(dark-blue) !important;
  }
}
.responsive-100vmax {
  @include media-breakpoint-down(md) {
    &.modal {
      .modal-dialog {
        margin: 0;
        width: 100%;
        max-width: 100%;
        .modal-content {
          border-radius: 0;
          margin: 0;
          width: 100%;
          border-radius: 10px;
          .form-group {
            flex: 1 1 auto;
          }
        }
      }
    }
  }
}
.backdrop-responsive-100vmax {
  @include media-breakpoint-down(md) {
    height: calc(100% - var(--get-nav-height) - 10px);
    top: calc(var(--get-nav-height) + 10px);
  }
}
.item-disabled {
  cursor: not-allowed !important;
  label {
    pointer-events: none !important;
    span {
      color: Sec_clr(medium-grey) !important;
    }
  }
}

.mar-sm-n7 {
  @include media-breakpoint-down(md) {
    margin-left: -7.5px;
    margin-left: -7.5px;
    [class*="col-"] {
      padding-left: 7.5px;
      padding-right: 7.5px;
    }
  }
}
.backdrop-md-hide {
  @include media-breakpoint-down(md) {
    display: none !important;
  }
}
input[type="number"] {
  -moz-appearance: textfield !important;
}
.promo_code {
  input {
    &::-webkit-input-placeholder {
      color: Sec_clr(lt-grey) !important;
      font-weight: 500;
      font-family: font-fam(Visby-cf) !important;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 12px;
    }
    &::-moz-placeholder {
      color: Sec_clr(lt-grey) !important;
      font-weight: 500;
      font-family: font-fam(Visby-cf) !important;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 12px;
    }
    &:-ms-input-placeholder {
      color: Sec_clr(lt-grey) !important;
      font-weight: 500;
      font-family: font-fam(Visby-cf) !important;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 12px;
    }
    &:-moz-placeholder {
      color: Sec_clr(lt-grey) !important;
      font-weight: 500;
      font-family: font-fam(Visby-cf) !important;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 12px;
    }
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
.modal-open {
  overflow-y: auto !important;
}
body.modal-open {
  padding-right: 0 !important;
}
.two-line-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  height: 60px;
  overflow: hidden;
  position: relative;
}
.three-line-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  height: 60px;
  overflow: hidden;
  position: relative;
}
.dot-style {
  padding: 0 5px;
  &::before {
    content: "";
    width: 5px;
    height: 5px;
    display: inline-block;
    border-radius: 50%;
    background-color: Pri_clr(dark-blue);
  }
}
.barCode_reader {
  letter-spacing: 10.5px;
  text-align: center;
  border: 1px solid #cccccc !important;
  @include media-breakpoint-down(md) {
    letter-spacing: 7.5px;
  }
  &::-webkit-inner-spin-button {
    display: none;
  }
}

.order-pickup-wrapper {
  .nav-link {
    &.active {
      border: 2px solid Pri-clr(dark-blue) !important;
      background: #fff !important;
      color: Pri_clr(lt-black);
    }
  }
  .too-far-Location {
    .nav-link {
      &.active {
        border: 2px solid #aa182c !important;
      }
    }
  }
}
.mx-md-n3 {
  @include media-breakpoint-up(md) {
    margin-left: -16px !important;
    margin-right: -16px !important;
  }
}
.try2-items-wrapper {
  @include media-breakpoint-down(md) {
    .container {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
.try2_dressing_modal {
  .modal-dialog-centered {
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    max-height: 100vh;
    @media (min-width: 768px) {
      width: 720px;
      max-width: 720px;
    }
    @media (min-width: 992px) {
      width: 960px;
      max-width: 960px;
    }
    @media (min-width: 1200px) {
      width: 1090px;
      max-width: 1090px;
    }
    @media (min-width: 1447px) {
      width: 1200px;
      max-width: 1200px;
    }
    @media (max-width: 767.98px) {
      min-height: 100% !important;
      .modal-content {
        align-self: end;
        border: none;
      }
    }
    .no-dressing-img {
      width: 44px;
    }
  }
  &.other-dressings-modal {
    .modal-dialog-centered {
      .modal-content {
        height: calc(100vh - 10vh);
      }
    }
  }
}
.scroll_modal {
  &.other-dressings-modal {
    .modal-dialog-centered {
      .modal-content {
        height: calc(100vh - 8vh) !important;
        @supports (-webkit-touch-callout: none) {
          height: calc(100vh - 4vh) !important;
        }
      }
    }
  }
}
.live_tracking_modal {
  @include media-breakpoint-up(md) {
    .modal-body {
      min-height: 600px !important;
    }
  }
  @include media-breakpoint-down(md) {
    .modal-content {
      align-self: flex-end !important;
      min-height: 450px;
    }
    .modal-body {
      padding: 0 !important;
    }
    .modal-dialog {
      margin: 0 !important;
    }
  }
}
.Modal_inner_scrollbar {
  height: calc(100vh - clamp(180px, 43vh, 300px)) !important;
  @include media-breakpoint-down(md) {
    height: calc(100vh - clamp(180px, 43vh, 330px)) !important;
    > div {
      overflow-x: hidden !important;
      overflow-y: auto !important;
      padding-top: 5px;
    }
  }
  .row {
    padding-right: 10px;
  }
  &.customization_inner_scrollbar {
    height: calc(100vh - clamp(180px, 43vh, 268px)) !important;
    > div {
      overflow-x: hidden !important;
      padding: 5px;
    }
    @include media-breakpoint-down(md) {
      height: calc(100vh - clamp(180px, 43vh, 255px)) !important;
    }
    @media screen and (max-width: 380px) {
      height: calc(100vh - clamp(180px, 43vh, 280px)) !important;
    }
  }
}
.Modal_inner_scrollbar_Extra_Protien {
  max-height: calc(100vh - clamp(180px, 43vh, 300px)) !important;
  overflow-y: auto;
  overflow-x: hidden;
}
.modifiers-accordions-wrapper {
  .accordion-item {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #cccccc;
    padding-bottom: 20px;
    margin-bottom: 40px;
    .accordion-header {
      .accordion-button {
        font-family: font_fam(Cls);
        font-size: font(s22);
        color: font_clr(dark-grey);
        // text-transform: uppercase;
        padding: 0;
        line-height: normal;
        align-items: flex-start;
        &:focus,
        &:not(.collapsed) {
          font-family: font_fam(Cls);
          font-size: font(s22);
          color: font_clr(dark-grey);
          outline: none !important;
          box-shadow: none !important;
          border: none !important;
          background-color: transparent;
          &::after {
            background-image: url("./images/accordion-polygon.svg");
            margin-left: 14px;
            width: 16px;
            height: 10px;
            background-size: contain;
            margin-top: 5px;
          }
        }
        &::after {
          background-image: url("./images/accordion-polygon.svg");
          margin-left: 14px;
          width: 19px;
          height: 12px;
          background-size: contain;
          margin-top: 5px;
        }
      }
    }
    @include media-breakpoint-down(md) {
      // border-bottom: none;
      // margin-bottom: 0;
      margin-bottom: 15px;
      .accordion-header {
        padding-bottom: 15px;
        .accordion-button {
          // font-size: font(s18);
          &::after {
            margin-left: 10px;
            width: 17px;
            height: 10px;
          }
        }
      }
    }
    .accordion-body {
      padding: 0;
    }
  }
}

.view-more-order-sorting {
  .dropdown {
    .dropdown-toggle {
      background: #fff !important;
      border: 0;
      text-transform: uppercase;
      @include font_sty(
        font(s16),
        Pri_clr(lt-black),
        font_fam(Visby-cf),
        $sm_lh: 24px,
        $fs_weight: 600
      );
      &:focus {
        box-shadow: none !important;
      }
    }
    .dropdown-menu {
      box-shadow: 0px 4px 4px #00000040;
      border: 0 !important;
      border-radius: 0 !important;
      padding: 10px 20px;
      z-index: 1;
      .dropdown-item {
        border-bottom: 1px solid #cccccc;
        padding: 12px 20px;
      }
    }
  }
}

/* Order Type Modal CSS */

.order_type_modal {
  .modal-dialog {
    max-width: 600px !important;
    margin: 0 auto;
    @include media-breakpoint-down(md) {
      min-height: 100% !important;
    }
  }
  .modal-content {
    padding: 0 !important;
    @include media-breakpoint-down(md) {
      align-self: flex-end;
      border-radius: 15px 15px 0px 0px !important;
      border: 0;
    }
  }
}
.delivery_location_select {
  .react-select__control {
    background: transparent !important;
    border-radius: 5px;
    border: none;
    box-shadow: inset 0px 0px 6px #44444440;
    font-size: 0.875rem;
    color: #3d3935;
    font-weight: 500;
    padding: 0.375rem 0.75rem;
    min-height: 51px;
    margin-top: 10px;
  }
}
@include media-breakpoint-down(md) {
  .full_width_mob {
    width: 100% !important;
  }
}
.item-customization-acc {
  .single-item-modifiers {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    @include media-breakpoint-down(md) {
      gap: 9px;
    }
    .item__modifier_item {
      max-width: calc(16.66% - 20px);
      width: calc(16.66% - 20px);
      min-width: calc(16.66% - 20px);
      @include media-breakpoint-down(md) {
        max-width: calc(33.33% - 6px);
        width: calc(33.33% - 6px);
        min-width: 100px;
      }
      @include media-breakpoint-down(sm) {
        min-width: 96px;
      }
      @media screen and (max-width: 365px) {
        min-width: 90px;
      }
    }
  }
}
.item__modifier_item_modal_width {
  max-width: calc(24.66% - 20px) !important;
  width: calc(24.66% - 20px) !important;
  min-width: calc(24.66% - 20px) !important;
  @media (max-width: 767px) {
    max-width: calc(33.33% - 6px) !important;
    width: calc(33.33% - 6px) !important;
    min-width: calc(33.33% - 6px) !important;
  }
}
.bowl-customization-modal {
  z-index: 1051;
}
.item-customization--modal {
  .modal-content {
    height: calc(100vh - 6vh) !important;
    padding: 0 !important;
    .Modal_inner_scrollbar {
      height: calc(90vh - 140px) !important;
      .px-0.container-fluid {
        padding: 0 10px !important;
      }
    }
  }
  @include media-breakpoint-down(md) {
    .modal-dialog.modal-dialog-centered {
      margin: 0;
      width: 100%;
      max-width: 100%;
      height: 100%;
      align-items: flex-end;
      .modal-content {
        height: calc(100vh - 10vh) !important;
        padding: 0 !important;
        .Modal_inner_scrollbar {
          height: calc(90vh - 200px) !important;
          .px-0.container-fluid {
            padding: 0 10px !important;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .accordion-button {
      width: 100%;
      // justify-content: space-between;
    }
  }
  .modal-dialog {
    max-width: 1200px !important;
    width: 100% !important;
  }
  .modal-content {
    padding: 0 100px !important;
  }
}
.single-itemOrder-wrap {
  .items--inner-wrap {
    gap: 14px;
    width: 100%;
    .add_on_item_wrap,
    .other-dressing-item-wrap {
      max-width: calc(32.33% - 9px);
      min-width: calc(32.33% - 9px);
      width: calc(32.33% - 9px);
      border-radius: 7px;
      .items__inn_content {
        padding: 0 1.5vw;
      }
    }
  }
  .items__inner_wrap {
    gap: 14px;
    .complimentry__items_wrap {
      max-width: calc(33.33% - 9px);
      width: calc(33.33% - 9px);
      margin-right: 0 !important;
      .add_on_item_wrap {
        border-radius: 7px;
        .items__inn_content {
          padding: 0 0.8vw;
        }
      }
    }
  }
}
@media (min-width: 767.98px) {
  .row-items {
    display: grid;
    grid-template-columns: auto auto auto auto;
  }
}
@media (max-width: 1024px) {
  .chose-item-modal {
    .row-items {
      display: grid;
      grid-template-columns: auto auto auto;
    }
    .shadow-box {
      padding: 20px 10px;
      min-height: 100%;
    }
  }
}

.chose-item-modal {
  .modal-dialog {
    margin: 0 auto;
    @include media-breakpoint-down(md) {
      min-height: 100% !important;
    }
  }
  .modal-content {
    @include media-breakpoint-down(md) {
      align-self: flex-end;
      border-radius: 15px 15px 0px 0px !important;
      border: 0;
      min-height: 100%;
    }
  }
  .modal-dialog-centered {
    min-height: calc(100%) !important;
  }
}
.optional-selector {
  &.size-selector {
    border: 1px solid #cccccc;
  }
}
.single-item-btn-wrap {
  gap: 24px;
  flex-wrap: wrap;
  margin-bottom: 16px;
  .btn.btn-large {
    width: calc(50% - 12px);
    max-width: unset;
    min-width: max-content;
    flex: 1 1 auto;
  }
  &.flex-column {
    gap: 16px;
    .btn.btn-large {
      width: 100%;
      max-width: 100%;
    }
  }
  @include media-breakpoint-down(lg) {
    gap: 15px;
    &.flex-column {
      flex-direction: row !important;
      .btn.btn-large {
        width: calc(50% - 8px);
        max-width: calc(50% - 8px);
      }
    }
    .btn.btn-large {
      width: calc(50% - 8px);
      max-width: calc(50% - 8px);
      font-size: 14px;
      padding: 8px 15px;
    }
  }
  .loader-position {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
  }
}
.single-item-info-wrap {
  @include media-breakpoint-up(lg) {
    .favourite-icon-wrap {
      transform: translateX(6vw);
    }
  }
}
.btn-disabled-bg:disabled {
  background-color: #afafaf !important;
  border-color: #afafaf;
}
.modifiers-box {
  box-shadow: 0px 2px 6px rgba(130, 130, 130, 0.25);
  border: 1px solid rgba(33, 33, 33, 0.08);
  .modifiers-scroll {
    max-height: 270px;
    overflow-x: hidden;
    overflow-y: visible;
  }
}
@include media-breakpoint-down(lg) {
  body.modal-open {
    overflow: hidden !important;
  }
}

.receipt-modal {
  .modal-content {
    max-height: 392px;
    padding: 0 20px;
  }
}
.receipt-modal-scan {
  .modal-content {
    max-height: none;
    height: auto !important;
  }
}

.search-track-here {
  top: 255px !important;
}
.no-track-here {
  .search-track-here {
    @include media-breakpoint-down(md) {
      top: 190px !important;
    }
  }
}

@include media-breakpoint-down(md) {
  .add-to-order-right {
    order: 1;
  }
}
.align-spiner-end {
  @include media-breakpoint-up(md) {
    align-items: flex-end !important;
  }
}
.full-modal-ipad {
  .modal-dialog {
    @media (max-width: 1200px) {
      max-width: 100%;
    }
  }
}
.input-checkbox {
  span {
    text-transform: none;
    font-weight: 500;
  }
  input[type="checkbox"] {
    width: 18px;
    height: 18px;
    &:checked {
      accent-color: Pri-clr(dark-blue);
      background: Pri-clr(dark-blue);
      border: none;
      border-radius: 2px;
    }
  }

  label {
    width: fit-content;
    cursor: pointer;
  }
}

.profile-info-read-only-field {
  .form-group {
    input:read-only {
      background-color: #fff !important;
    }
  }
}
.border-size-select {
  animation: blink 0.5s step-end infinite alternate;
  // border: 2px solid #aa182c !important;
  // animation-iteration-count: 3;
}
@keyframes blink {
  50% {
    border-color: #aa182c;
    border: 2px solid #aa182c;
  }
}
.split-input {
  @media (max-width: 380px) {
    padding: 0.375rem 0rem;
  }
}

.new_form_design {
  .StripeElement {
    font-size: 0.875rem;
    color: #3d3935;
    font-family: "Visby CF";
    height: 51px;
    font-weight: 500;
    border: none;
    background-color: #fff;
    box-shadow: inset 0px 0px 6px #44444440;
    border-radius: 9px;
    padding: 10px;
  }
}
.sticky-add-to-cart {
  background-color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 0;
  box-shadow: 0px 0px 11px #3d393580;
  z-index: 2;
  min-height: 66px;
  .sticky-btn-wrap {
    max-width: 570px;
    @media screen and (max-width: 600px) {
      button {
        max-width: 170px !important;
        min-width: 170px;
      }
    }
  }
  .salad-sticky-btn {
    max-width: 280px;
    min-width: 165px;
    @media screen and (max-width: 600px) {
      button {
        font-size: 12px !important;
      }
    }
  }
  @media screen and (max-width: 600px) {
    .combo {
      button {
        min-width: 334px;
        max-width: 170px;
        font-size: 12px;
        height: 100%;
      }
    }
    .saladCombo {
      button {
        min-width: 194px !important;
        max-width: 170px;
        font-size: 12px;
        height: 100%;
      }
    }
  }
}
.store-location-image {
  @include media-breakpoint-down(md) {
    width: 80px;
    height: 80px;
  }
}

.external_payments {
  margin: 25px 0px;
}

.autocomplete-dropdown-container {
  box-shadow: 0px 4px 8px #44444440;
  border-radius: 9px;
  border: none;
  margin-top: 1px;
  font-family: "Proxima Nova Lt";
  font-weight: 500;
  line-height: normal;
  position: absolute;
  background: white;
  z-index: 1;
  max-height: 200px;
  overflow-y: visible;
  overflow-x: hidden;
  margin-right: 10px;
  min-width: 100%;
  .loading {
    padding: 10px;
  }

  .suggestion-item {
    padding: 10px 10px;
  }

  .suggestion-item--active {
    padding: 10px 10px;
    cursor: pointer;
  }
}

.added-modal-size .modal-content,
.added-modal-size .modal-dialog {
  @include media-breakpoint-up(md) {
    width: 525px !important;
    max-width: 525px !important;
  }
}
.catering-menu {
  .menu_tabs_wrap.blue {
    .nav-link.active {
      background-color: Sec_clr(dark-red) !important;
      border-color: Sec_clr(dark-red) !important;
    }
  }
}
.catering-menu {
  .menu_tabs_wrap.blue {
    .nav-link {
      border-radius: 5px !important;
      &:hover {
        background-color: Sec_clr(dark-red) !important;
        border-color: Sec_clr(dark-red) !important;
      }
    }
  }
}
.gmnoprint.gm-style-cc {
  right: 52px;
  top: 5px;
}

.cky-notice-des {
  p {
    span {
      font-size: 1rem !important;
      color: #3d3935 !important;
      font-family: "Raleway" !important;
      font-weight: 500 !important;
      line-height: 19.9px !important;
      letter-spacing: normal !important;
    }
  }
}

.cky-btn-accept {
  border-radius: 60px !important;
  font-family: "visby_cfextra_bold" !important;
  font-weight: 500 !important;
  min-width: 140px !important;
  padding: 6px 15px !important;
}

.cky-policy {
  font-family: "Visby CF Extra" !important;
  color: #3d3935 !important;
}

.cky-consent-container {
  .cky-consent-bar {
    box-shadow: 0px 0px 11px #3d393580 !important;
  }
}
.dressing_modal_ms_mob {
  .modal-content {
    @media screen and (max-width: 400px) {
      padding: 0 5px !important;
    }
  }
}
.signin-link-dropdown .dropdown-menu .dropdown-item {
  color: font_clr(dark-grey) !important;
  padding: 12px 0;
}

body > iframe {
  pointer-events: none;
}

.jobMega {
  position: fixed;
  top: 0;
  background: white;
  transform: translateY(-100%);
  transition: 0.5s all;
  z-index: 2;
  padding-bottom: 50px;
  width: 100%;
  &.isMenuShown {
    transform: translateY(0%);
    top: 208px;
    z-index: 99;
    @media (max-width: 767.98px) {
      top: 183px;
      border-top: 1px solid #e1e1e1;
    }
    @media (min-width: 1180px) {
      top: 220px;
    }
    &.trackBarExist {
      @media (min-width: 767.98px) {
        top: 273px !important;
      }
      top: 228px;
      border-top: 1px solid #e1e1e1;
    }
  }
  @media (max-width: 767.98px) {
    top: 156px;
    border-top: 1px solid #e1e1e1;
    padding-bottom: 24px;
  }
}

.menuOverlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: #000;
  opacity: 0.5;
  top: 0;
}
.gutter-space {
  --bs-gutter-x: 12px !important;
}
.margin-0 {
  margin-bottom: 0 !important; 
}

.signup-p {
  margin: 1rem 2rem 2rem 2rem !important; 
  font-size: 1rem !important;
}