@import "../../styles/base/_baseModule";
.live_tracker_loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.customize_content_wrapper {
  margin-left: 4vw;
  margin-right: 4vw;
  @media (max-width: 767.98px) {
    margin-left: 3vw;
    margin-right: 3vw;
  }
  .customize_card_header {
    border-bottom: 1px solid Sec_clr(dark-red) !important;
  }
  .items_inner_wrap {
    gap: 16px;
    @media (max-width: 575.98px) {
      gap: 4px;
    }
    .items_wrap {
      margin-bottom: 48px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .item_wrap {
      padding: 15px 3.75vw;
      max-width: calc(20% - 13px);
      width: calc(20% - 13px);
      min-width: 200px;
      border: 1px solid #cccccc;
      box-shadow: 0px 0px 8.66509px rgba(61, 57, 53, 0.2);
      border-radius: 2.3px;
      text-align: center;
      @media (max-width: 1199.98px) {
        padding: 15px 2.75vw;
      }
      @media (max-width: 991.98px) {
        max-width: calc(25% - 12px);
        width: calc(25% - 12px);
      }
      @media (max-width: 767.98px) {
        padding: 15px 2vw;
        min-width: 105px;
        max-width: calc(33.33% - 13px);
        width: calc(33.33% - 13px);
      }
      @media (max-width: 575.98px) {
        max-width: calc(33.33% - 3px);
        width: calc(33.33% - 3px);
      }
    }
    .Add_more_btn {
      outline: none !important;
      box-shadow: none !important;
    }
  }
}
.item_detals {
  font-size: font(s10) !important;
  letter-spacing: normal !important;
  height: 10px;
}
.item_increments {
  .increase_btns {
    background: url("../../images/plus-icon.svg") no-repeat;
    background-size: contain;
    width: 28px;
    min-width: 28px;
    height: 28px;
    line-height: 0;
    padding: 0;
    outline: none !important;
    box-shadow: none !important;
    font-size: 0;
    border: none !important;
    &.add_btn {
      background: url("../../images/plus-colored-icon.svg") no-repeat;
      background-size: contain;
    }
  }
  .decrease_btns {
    @extend .increase_btns;
    background: url("../../images/minus-icon.svg") no-repeat;
    background-size: contain;
    font-size: 0;
  }
  .counts {
    width: 60px;
    display: inline-block;
    padding: 0 10px;
    font-size: font(s18);
    font-weight: 500;
    text-align: center;
  }
}
.Items_list_modal {
  .modal_header {
    margin-bottom: 25px;
    padding-bottom: 0;
    border: none;
    .customize_card_header {
      position: relative;
      .modal_close_button {
        width: 23px;
        height: 23px;
        padding: 0;
        display: inline-block;
        background-color: transparent;
        background: url("#{$REACT_APP_CLOUD_FRONT}/images/icons/x-icon.svg")
          no-repeat;
        background-size: contain;
        opacity: 1;
        outline: none !important;
        box-shadow: none !important;
        position: absolute;
        top: 12px;
        right: 15px;
        z-index: 999;
        @media (min-width: 768px) {
          top: 15px;
          right: -35px;
        }
      }
    }
  }
  .menu_tabs {
    place-content: start;
    border-bottom: 0;
    margin-bottom: 0;
    padding: 12px 16px;
    @media (max-width: 767.98px) {
      padding: 12px 11px;
    }
    a {
      @include font-sty(
        font(s14),
        Pri_clr(lt-black),
        font_fam(rale),
        $sm_fs: font(s14),
        $fs_weight: 500
      );
      border-radius: 5px !important;
      margin-right: 10px;
      padding: 5px 14px;
      min-width: max-content;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .items_inner_wrap {
    .item_wrap {
      max-width: calc(25% - 12px);
      width: calc(25% - 12px);
      padding: 15px 2.5vw;
      @media (max-width: 767.98px) {
        min-width: 105px;
        max-width: calc(33.33% - 13px);
        width: calc(33.33% - 13px);
        padding: 15px 2vw;
      }
    }
  }
}
.order_summary_wrap {
  ul {
    li {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 60px;
      }
    }
  }
}
.checkout_totals {
  ul {
    li {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      font-size: font(s18);
      color: Pri_clr(lt-black);
      @include media-breakpoint-down(md) {
        font-size: font(s16);
      }
    }
  }
}
.payment_details {
  .price_card_wrap {
    background-color: Pri_clr(lt-black);
    border-radius: 8px;
    color: #fff;
    padding: 15px;
  }
}
.payment_details {
  .custom_col {
    padding-right: 5px;
    padding-left: 5px;
    flex: 0 0 13.66667%;
    max-width: 13.66667%;
    input {
      height: 38px;
      padding: 6px;
      text-align: center;
    }
  }
}
.card_header {
  padding: 38px 16px 16px;
  // @media (max-width: 767.98px){
  //   padding-top: 25px;
  // }
}
.item_main_div {
  .no_track_order_detail_wrapper {
    margin-top: 250px;
  }
  .track_here_order_detail_wrapper {
    margin-top: 295px;
  }
  .item_info_wrap {
    padding-left: 24px;
    padding-right: 24px;
    max-width: 565px;
    @media (max-width: 991px) {
      max-width: 100%;
      margin: 0 auto;
    }
    @media (min-width: 992px) {
      max-width: 34.66667%;
      flex: 0 0 34.66667%;
      padding-left: 15px;
      padding-right: 15px;
    }
    .selector_wrapper {
      .items_selector {
        gap: 16px;
        &.item_size_selector {
          .item_selector {
            width: calc(50% - 10px);
          }
        }
        @media (max-width: 575.98px) {
          gap: 6px;
          h6 {
            letter-spacing: normal;
            span {
              letter-spacing: normal;
            }
          }
        }
      }
    }
  }
}
.item_title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 18.2px !important;
  overflow: hidden;
  font-size: font(s14) !important;
  @media (max-width: 767.98px) {
    line-height: normal !important;
    font-size: font(s11) !important;
  }
}
.customize_page {
  .back_button_link {
    @media (max-width: 767.98px) {
      position: absolute;
      top: 21px;
      left: 0;
    }
  }
}
.orderDetials_wrap {
  max-width: 560px;
}
.map_wrapper {
  width: 100%;
}
.ser_user_img_wrap {
  width: 87px;
  min-width: 87px;
  height: 87px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  @media (max-width: 767.98px) {
    width: 62px;
    min-width: 62px;
    height: 62px;
  }
}
.pickup_time_wrap {
  .time_selector_wrap {
    gap: 10px;
    padding-right: 10px;
    align-items: flex-end;
    .time_selector_item {
      flex: 1 1 auto;
      max-width: 178px;
      input {
        display: none;
        &:checked {
          & + label {
            border-color: Pri_clr(dark-blue);
            background-color: Pri_clr(dark-blue);
            color: #fff;
          }
        }
      }
      label {
        border: 1px solid #3d3935;
        border-radius: 3px;
        padding: 8px 15px;
        font-size: font(s22);
        font-family: font_fam(Cls);
        text-align: center;
        margin-bottom: 0;
        width: 100%;
        @media (max-width: 767.98px) {
          font-size: font(s18);
          padding: 8px 10px;
        }
      }
      &:first-child {
        min-width: 256px;
        margin-top: 32px;
        label {
          position: relative;
          &::before {
            content: "FASTEST!";
            position: absolute;
            top: -32px;
            left: 50%;
            width: 70px;
            color: #fff;
            background-color: Pri_clr(lt-black);
            font-size: font(s11);
            font-family: font_fam(Vcf);
            padding: 4px 8px;
            transform: translateX(-50%);
            @media (max-width: 767.98px) {
              padding: 2px 8px;
            }
          }
        }
        @media (max-width: 767.98px) {
          min-width: 220px;
        }
      }
      @media (max-width: 767.98px) {
        max-width: 110px;
      }
    }
  }
}
.time_selector_Scrollbar {
  height: 205px !important;
  .Scrollbar_thumb {
    width: 4px !important;
    background-color: #cccccc;
    border-radius: 3px;
    margin-left: auto;
  }
  @media (max-width: 767.98px) {
    height: 178px !important;
  }
}

.order_confirmation_container {
  max-width: 908px;
  .confirmation_content {
    .sub_title {
      letter-spacing: 1.5px;
      max-width: 575px;
      @media (max-width: 767.98px) {
        max-width: 320px;
        letter-spacing: 0.75px;
        line-height: normal;
      }
    }
    .location_image {
      img {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        @media (max-width: 767.98px) {
          width: 107px;
          height: 107px;
        }
      }
    }
    .map_wrapper {
      height: 200px;
      @media (max-width: 767.98px) {
        height: 170px;
      }
      @media (max-width: 575.98px) {
        width: calc(100% + 30px);
        margin-left: -15px;
      }
    }
    .order_details {
      max-width: 580px;
    }
    hr {
      @media (max-width: 575.98px) {
        width: calc(100% + 30px);
        margin-left: -15px;
      }
    }
  }
  .notification_wrap {
    background-color: #f3f3f3;
    padding: 40px;
    margin-bottom: 65px;
    @media (max-width: 575.98px) {
      width: calc(100% + 30px);
      margin-left: -15px;
      margin-bottom: 40px;
    }
  }
}
.order_confirmation_delivery {
  .location_image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    background-color: #3d3935;
    img {
      width: 70px !important;
    }
    @media (max-width: 767.98px) {
      width: 107px;
      height: 107px;
      img {
        width: 50px !important;
      }
    }
  }
}
.checkout_wrapper {
  .map_wrapper {
    height: 164px;
    @media (max-width: 575.98px) {
      width: calc(100% + 30px);
      margin-left: -15px;
      margin-bottom: 40px;
      height: 156px;
    }
  }
  .checkout_container {
    max-width: 860px;
    width: 100%;
    margin: 0 auto;
  }
}
.redeem_selector_btn {
  display: flex !important;
  justify-content: center;
  align-items: flex-start;
  border-radius: 4px !important;
  min-width: auto !important;
  width: 100%;
  max-width: 100%;
}
.display_none {
  display: none;
}
.font-weight-500 {
  font-weight: 500;
}
.own_salad_wrapper {
  width: 100%;
  .salads_nav_wrapper {
    gap: 16px;
    .nav_item_wrap {
      min-height: 221px;
      padding: 0 !important;
      width: calc(20% - 13px);
      min-width: 200px;
      @media (max-width: 767.98px) {
        min-height: 160px;
      }
      .categories_button {
        outline: none !important;
        padding: 15px 1.75vw !important;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }
  }
}
.order_details_card_wrapper {
  border: 1px solid #cccccc;
  box-shadow: 0px 0px 8.66509px #3d393533;
  border-radius: 6px;
  padding: 30px 18px;
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
  .order_details_img {
    img {
      width: 100px;
      height: 82px;
    }
  }
}
.less_more_btn {
  margin-top: -20px !important;
  border-radius: 0px 0px 9px 9px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  @include media-breakpoint-down(md) {
    margin-top: -5px;
  }
}
.order_parent {
  @include media-breakpoint-down(md) {
    background: #ffffff;
    border: 1px solid rgba(33, 33, 33, 0.08);
    box-shadow: 0px 2px 6px rgba(130, 130, 130, 0.25);
    border-radius: 10px;
  }
  .order_details_card_wrapper {
    @include media-breakpoint-down(md) {
      margin-bottom: 0;
      border: none;
      box-shadow: none;
      padding: 15px 18px;
    }
  }
  .error_msg {
    br {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }
}
.order_details_card_error {
  border: 1px solid #aa182c;
  h5 {
    color: #aa182c;
  }
}
.order_item_order_btn {
  @include media-breakpoint-down(sm) {
    min-width: 135px !important;
    width: 156px !important;
    font-size: font(s13);
    padding: 9px 10px;
  }
}

.tipping_wrapper {
  .tip_custom_input {
    input[type="text"] {
      -moz-appearance: textfield !important;
      border-radius: 22px;
      border: none;
      box-shadow: 1px 1px 5px #c0c0c0de;
      height: 35px;
      width: 75px;
      outline: none;
      text-align: center;
      padding: 0 10px;
      font-size: font(s16);
      font-family: "Raleway";
      color: #3d3935;
      cursor: pointer;
      &:last-child {
        width: 156px;
        cursor: default;
      }
      &:focus {
        background: Pri_clr(dark-blue);
        color: #fff;
      }
      &:focus::placeholder {
        color: transparent !important;
      }
      @include media-breakpoint-down(md) {
        width: 50px;
        height: 30px;
        font-size: font(s14);
        &:last-child {
          width: 130px;
        }
      }
    }
    .selected_tip {
      background: Pri_clr(dark-blue);
      color: #fff !important;
      &::placeholder {
        color: #fff !important;
      }
    }
  }
}

.change_location_wrapper {
  .search_bar_wrap {
    background: #ffffff;
    padding: 0px 4px;
    box-shadow: 0px 1px 4px #00000040;
    border-radius: 22px;
    overflow: hidden;
    max-width: 400px;
    width: 100%;
    .search_inn_content {
      background-color: #fff;
      .search_bar_icon {
        cursor: pointer;
        position: relative;
        top: -1px;
      }
      .form_control {
        border: none;
        box-shadow: none !important;
        outline: none !important;
        border-radius: 0;
        font-family: "Visby CF";
        font-size: 14px;
      }
    }
  }
  .location_card {
    color: Pri_clr(lt-black);
    text-decoration: none;
    max-width: 400px;
    width: 100%;
    padding: 19px 10px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    &.active {
      transform: scale(1.05);
      box-shadow: 0px 0px 6px #0000001c;
      padding: 10px;
      border-radius: 6px;
      border-bottom: none;
    }
    .location_image {
      img {
        border-radius: 50%;
        height: 60px;
        width: 60px;
      }
    }
    .location_info {
      display: none;
    }
    .card_info_content {
      span {
        white-space: nowrap;
      }
    }
  }

  .location_in_active {
    cursor: not-allowed !important;
  }

  .selected_Location_card {
    box-shadow: 0px 2px 6px #82828240;
    border-radius: 7px;
    padding: 19px 10px;
    .location_image {
      img {
        border-radius: 50%;
        height: 60px;
        width: 60px;
        padding: 2px;
        border: 4px solid Pri-clr(dark-blue);
      }
    }
    .location_info {
      display: block;
    }
  }
  .border_bottom {
    max-width: 400px;
    width: 100%;
  }
  .change_location_map_wrapper {
    height: 419px;
    // width: 702px;
    border: 1px solid #767676;
    border-radius: 8px;
    overflow: hidden;
  }
}
.order_parent {
  .detail_error {
    color: Sec_clr(dark-red) !important;
    border: 1px solid Sec_clr(dark-red) !important;
  }
}
.card_map_scroll {
  max-height: 450px;
  overflow-x: hidden;
  overflow-y: visible;
  &::-webkit-scrollbar {
    background: transparent;
    width: 4px !important;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    width: 10px !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 3px;
    width: 4px !important;
  }
  @include media-breakpoint-down(md) {
    height: auto;
    max-height: max-content;
  }
}
.dollar_sign {
  position: absolute;
  left: 5%;
  top: 18%;
  color: Sec-clr(sec-white);
}

.order_detail_mobile_card {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 4px 10px #0000001a;
  border-radius: 10px;
  padding: 13px 8px;
  margin-bottom: 16px;
}
.sticky_btn_space {
  margin-bottom: 66px;
}

.trash_icon_red {
  filter: invert(21%) sepia(100%) saturate(7414%) hue-rotate(359deg)
    brightness(94%) contrast(117%);
  position: absolute;
  right: 0px;
  top: 25px;
}
.split_credit_gift {
  position: relative;
  left: 10px;
}
.split_input {
  max-width: 110px;
  width: 100%;
}
.added_modal_btn {
  min-width: auto;
}
.store_image_icon {
  width: 87px;
  min-width: 87px;
  height: 87px;
  margin: 0 auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}
.img_fluid_border {
  border: 3px solid Pri_clr(dark-blue);
}
.orderModalImage {
  height: 230px;
  width: auto;
  margin: 0 auto;
  @include media-breakpoint-down(md) {
    height: 150px;
  }
}
