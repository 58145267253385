@import "../../styles/base/_baseModule.scss";

.topHeaderWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0;
  gap: 16px;

  .switcher {
    padding: 0px;
    border-radius: 0 50px 50px 0;
    gap: 4px;
    background: $cz_beige;
    display: flex;
    justify-content: space-around;
    overflow: hidden;
    position: relative;
    left: -43px;
    box-shadow: -2px 2px 5px 0px #00000040;
    @media (max-width: 767.98px) {
      padding: 0px;
      border-radius: 50px;
      left: 0;
    }
    .switcherLink {
      font-family: "trade-gothic-next",sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: 0.05em;
      text-align: center;
      color: $white;
      text-decoration: none;
      padding: 8px 45px;
      position: relative;
      text-transform: uppercase;
      overflow: hidden;
      color: $cz_green;
      text-decoration: underline;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 767.98px) {
        font-size: 14px;
        line-height: 14px;
        padding: 4px 15px;
        font-weight: 700;
        letter-spacing: -0.04em;
        text-align: center;
        text-decoration: none;
        svg {
          height: 20px;
        }
      }
    }

  }
  .locationNavSection {
    font-family: "trade-gothic-next",sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0.05em;
    text-align: left;
    color: black;
    background: transparent;
    border: 0;
    text-decoration: underline;
    text-transform: uppercase;
  }
  .SignInBtn {
    font-family: "trade-gothic-next",sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.42px;
    text-transform: uppercase;
    color: black;
  }
}

.orderTrackBar {
  background: $cz_sharp_yellow;
  border-radius: 42px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: -2px 2px 5px 0px #00000040;
  margin-bottom: 16px;
  padding: 4px 0;
  @media (max-width: 767.98px) {
    margin-bottom: 8px;
    padding: 2px 0;
  }
}
